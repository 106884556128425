export const enUsLocaleName = "en-us";
export const ukUaLocaleName = "uk-ua";

export const backendEnUsLocaleName = "en";
export const backendUkUaLocaleName = "ua";

export const backendFrontendLocalsMap = {
    [enUsLocaleName]: backendEnUsLocaleName,
    [ukUaLocaleName]: backendUkUaLocaleName,
};

const defaultLocaleMap: Record<string, keyof typeof backendFrontendLocalsMap> = {
    uk: ukUaLocaleName,
    ru: ukUaLocaleName,
    en: enUsLocaleName,
};

export const fallbackLocale = ukUaLocaleName;

export const getDefaultLocale = () => defaultLocaleMap[window.navigator.language.slice(0, 2)] ?? fallbackLocale;
