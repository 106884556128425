import { ref, computed } from "vue";
import JsSIP from "jssip";
import { RTCSession } from "jssip/lib/RTCSession";
import { useRootStore } from "@/utils/hooks";
import { decrypt } from "@/utils/crypto";
import { usePublicIp } from "../usePublicIp";
import { UserCallStatus } from "@/services/enums/user-call-status.enum";
import { CallTypes } from "@/services/enums/call-types.enum";
import { callRoles } from "@/services/enums/call-roles.enum";

export function useSipConnection() {
    const store = useRootStore();
    const { getMyPublicIp, myPublicIp } = usePublicIp();

    let session: RTCSession | null = null;

    const getSession = () => session;

    const currentCallRole = ref(callRoles.callee);
    let callStartTime: string = new Date().toISOString();

    const sipRegistered = computed(() => store.state.calls.sipRegistered);
    const sipNumber = computed(() => store.state.auth?.sipNumber);
    const sipPassword = computed(() => store.state.auth?.sipPassword);
    const hasActiveCallSession = computed(() => store.state.calls.hasActiveCallSession);
    const isDialing = computed(() => store.state.calls.isDialing);
    const phoneNumber = computed(() => store.state.calls.phoneNumber);
    const remotePhoneNumber = computed(() => store.state.calls.remotePhoneNumber);

    // Config
    const sipWSProtocol = process.env.VUE_APP_SIP_WEBSOCKET_PROTOCOL;
    const sipWSPort = process.env.VUE_APP_SIP_WEBSOCKET_PORT;
    const sipWSHost = process.env.VUE_APP_SIP_WEBSOCKET_HOST;

    let ua: JsSIP.UA | null = null;

    // Core SIP options
    const sipOptions = {
        pcConfig: {
            rtcpMuxPolicy: "negotiate",
            iceServers: [{ urls: ["stun:stun.l.google.com:19302"] }],
        },
        mediaConstraints: {
            audio: true,
            video: false,
        },
        rtcOfferConstraints: {
            offerToReceiveAudio: 1,
        },
    };

    // SIP User Call Status
    const userCallStatus = computed(() => store.state.auth.callStatus);
    const setUserCallStatus = (newCallStatus: UserCallStatus) => {
        store.commit("auth/setCallStatus", newCallStatus);
        localStorage.setItem("callStatus", newCallStatus);
    };

    // Call utils
    const startCallTracking = () => {
        callStartTime = new Date().toISOString();
    };

    // External handlers to be set by the parent component
    let onIncomingCall: ((callerNumber: string) => void) | null = null;
    let onCallEnded: (() => void) | null = null;
    let onCallAccepted: (() => void) | null = null;
    let onCallFailed: ((cause: string) => void) | null = null;
    let onCallRinging: (() => void) | null = null;
    let onStopTimer: (() => void) | null = null;
    let onStartTimer: (() => void) | null = null;
    let onSetRemoteUserName: ((phoneNumber: string) => Promise<void>) | null = null;

    // Set event handlers
    const setEventHandlers = (handlers: {
        onIncomingCall?: (callerNumber: string) => void;
        onCallEnded?: () => void;
        onCallAccepted?: () => void;
        onCallFailed?: (cause: string) => void;
        onCallRinging?: () => void;
        onStopTimer?: () => void;
        onStartTimer?: () => void;
        onSetRemoteUserName?: (phoneNumber: string) => Promise<void>;
    }) => {
        onIncomingCall = handlers.onIncomingCall || null;
        onCallEnded = handlers.onCallEnded || null;
        onCallAccepted = handlers.onCallAccepted || null;
        onCallFailed = handlers.onCallFailed || null;
        onCallRinging = handlers.onCallRinging || null;
        onStopTimer = handlers.onStopTimer || null;
        onStartTimer = handlers.onStartTimer || null;
        onSetRemoteUserName = handlers.onSetRemoteUserName || null;
    };

    // Initialize SIP connection
    const initSip = async (isActiveTab: boolean) => {
        if (!isActiveTab) {
            console.log("initSip: Not active tab, skipping SIP initialization");
            return null;
        }

        if (!sipNumber.value || !sipPassword.value) {
            console.error("Missing SIP credentials");
            return null;
        }

        const decryptedPassword = await decrypt(sipPassword.value as string);
        await getMyPublicIp();

        const socket = new JsSIP.WebSocketInterface(`${sipWSProtocol}://${sipWSHost}:${sipWSPort}/ws`);
        const configuration = {
            sockets: [socket],
            uri: `sip:${sipNumber.value}@${sipWSHost}`,
            password: decryptedPassword,
            register: true,
            ...sipOptions,
            connection_recovery_min_interval: 1,
            connection_recovery_max_interval: 1,
        };

        ua = new JsSIP.UA(configuration);
        JsSIP.debug.disable();

        // Critical WebRTC session handling
        ua.on("newRTCSession", async (data: { session: RTCSession }) => {
            if (!isActiveTab && data.session.direction === "incoming") {
                console.log("Non-active tab: ignoring incoming call session");
                data.session.terminate();
                return;
            }

            if (data.session.direction === "incoming" && hasActiveCallSession.value) {
                console.log("Incoming call rejected: active call already exists");
                data.session.terminate();
                return;
            }

            const prevCallStatus = userCallStatus.value;

            if (data.session.direction === "incoming") {
                const remoteIdentity = data.session?.remote_identity;
                const callerNumber = remoteIdentity?.uri?.user;

                console.log(`Incoming call from ${callerNumber}`);

                if (session) {
                    try {
                        const oldSession = session;
                        session = null;

                        oldSession.removeAllListeners();
                        oldSession.terminate();

                        store.commit("calls/setActiveCallSession", false);
                        store.commit("calls/setIsDialing", false);
                        store.commit("calls/setHold", false);
                        store.commit("calls/setMuted", false);
                        if (onStopTimer) onStopTimer();

                        console.log("Existing session forcefully terminated");
                    } catch (error) {
                        console.error("Error cleaning up existing session:", error);
                    }
                }

                store.commit("calls/setPhoneNumber", null);
            }

            session = data.session;
            console.log("Session set:", session);

            let iceCandidateTimeout: number | null = null;
            data.session.on("icecandidate", (event) => {
                const iceCandidate = event?.candidate;
                if (iceCandidateTimeout != null) clearTimeout(iceCandidateTimeout);

                iceCandidateTimeout = setTimeout(() => event.ready(), 5000);

                if (
                    iceCandidate &&
                    iceCandidate.address !== myPublicIp.value &&
                    iceCandidate.type === "srflx" &&
                    iceCandidate.relatedAddress &&
                    iceCandidate.relatedPort
                ) {
                    if (iceCandidateTimeout != null) {
                        event.ready();
                    }
                }
            });

            data.session
                .on("ended", async () => {
                    console.log("Call ended");

                    if (session) {
                        const sessionToCleanup = session;
                        session = null;

                        try {
                            sessionToCleanup.removeAllListeners();
                        } catch (error) {
                            console.error("Error cleaning up ended session:", error);
                        }
                    }

                    if (onCallEnded) onCallEnded();
                    setUserCallStatus(prevCallStatus);

                    store.commit("calls/clearState");
                })
                .on("accepted", () => {
                    console.log("Call accepted");
                    if (onCallAccepted) onCallAccepted();
                    setUserCallStatus(UserCallStatus.BUSY);
                    store.commit("calls/setActiveCallSession", true);
                    store.commit("calls/setIsDialing", false);
                    if (onStartTimer) onStartTimer();
                })
                .on("failed", (err: any) => {
                    if (err.originator === "remote" && (err.cause === "Rejected" || err.cause === "Canceled")) {
                        console.log("Call was canceled or the recipient is busy");
                        if (onCallFailed) onCallFailed(err.cause);
                    } else {
                        console.error("Call failed", err);
                        if (onCallFailed) onCallFailed(err.cause || "Unknown");
                    }
                    setUserCallStatus(prevCallStatus);
                });

            if (userCallStatus.value === UserCallStatus.ACTIVE) {
                if (data.session.direction === "incoming") {
                    if (onCallRinging && sipRegistered.value) onCallRinging();

                    currentCallRole.value = callRoles.callee;

                    const remoteIdentity = data.session?.remote_identity;
                    const callerNumber = remoteIdentity?.uri?.user;

                    if (callerNumber) {
                        store.commit("calls/setRemotePhoneNumber", callerNumber);
                        if (onSetRemoteUserName) await onSetRemoteUserName(callerNumber);
                        store.commit("calls/setIsDialing", true);
                        if (onIncomingCall) onIncomingCall(callerNumber);
                    }
                }
            } else if ([UserCallStatus.BUSY, UserCallStatus.DND].includes(userCallStatus.value)) {
                if (data.session.direction === "incoming") {
                    if (session) {
                        const sessionToTerminate = session;
                        session = null;

                        try {
                            sessionToTerminate.removeAllListeners();
                            sessionToTerminate.terminate();
                        } catch (error) {
                            console.error("Error terminating session:", error);
                        }
                    }
                    console.log(`Call rejected by cause: user status is: "${userCallStatus.value}"`);
                }
            }
        });

        // Set up core SIP event handlers (registration status)
        ua.on("registered", () => {
            store.commit("calls/setSipRegistered", true);
        })
            .on("connected", () => {
                console.log("SIP connected");
                store.commit("calls/setSipRegistered", true);
            })
            .on("disconnected", () => {
                console.error("SIP disconnected");
                store.commit("calls/setSipRegistered", false);
            })
            .on("registrationFailed", () => {
                console.error("SIP registration failed");
                store.commit("calls/setSipRegistered", false);
            });

        ua.start();

        // Set up keep-alive mechanism
        setInterval(() => {
            if (sipRegistered.value) {
                if (sipNumber.value && sipWSHost) {
                    try {
                        // @ts-ignore
                        ua.sendOptions(`sip:${sipNumber.value}@${sipWSHost}`, null, {});
                    } catch (e) {
                        if (process.env.NODE_ENV === "development") {
                            console.log("Keep-alive ping error (safe to ignore):", e);
                        }
                    }
                } else {
                    console.error("No sip available");
                }
            }
        }, 10000);

        return ua;
    };

    // Stop SIP connection
    const stopSip = () => {
        console.log("Stop sip connection");

        if (session) {
            const sessionToCleanup = session;
            session = null;
            try {
                sessionToCleanup.removeAllListeners();
                sessionToCleanup.terminate();
            } catch (error) {
                console.error("Error cleaning up session during SIP stop:", error);
            }
        }

        store.commit("calls/clearState");

        ua && ua.stop();
    };

    // Check if SIP needs reconnection
    const reconnectSipIfNeeded = (isActiveTab: boolean, hasActiveCallSession: boolean) => {
        if (sipNumber.value && sipPassword.value && isActiveTab && !hasActiveCallSession && !sipRegistered.value) {
            initSip(isActiveTab);
        }
    };

    return {
        sipOptions,
        sipRegistered,
        sipNumber,
        sipPassword,
        sipWSHost,
        userCallStatus,
        setUserCallStatus,
        initSip,
        stopSip,
        reconnectSipIfNeeded,
        currentCallRole,
        callRoles,
        startCallTracking,
        setEventHandlers,
        getSession,
    };
}
