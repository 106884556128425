import { authService } from "@/services";
import {
    ConfirmEmail,
    Login,
    Profile,
    ResendCode,
    ResetPasswordConfirmation,
    SignUp,
    SignUpByInvitation,
    SignUpInvitationCheck,
    TwoFaAuthenticate,
    UpdateProfile,
} from "@/services/types";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { AxiosResponse } from "axios";
import { ActionContext, useStore } from "vuex";
import { UserCallStatus } from "@/services/enums/user-call-status.enum";

type LoginResponse = AxiosResponse<{ _: string }> | undefined;
type SignUpResponse = AxiosResponse<{ _: string }> | undefined;
type ResendCodeResponse = AxiosResponse<{ _: string }> | undefined;
type ConfirmEmailResponse = AxiosResponse<{ _: string }> | undefined;
type SignUpEmail = string | undefined;
type ResetPasswordEmail = string | undefined;
type ResetPasswordCode = string | undefined;
type SignInGoogleResponse = { url: string } | undefined;
type Generate2FAResponse = AxiosResponse<string> | undefined;
type Authenticate2FAResponse = AxiosResponse<{ _: string }> | undefined;
type Context = ActionContext<unknown, unknown>;

export interface AuthState {
    isLoadingProfile: boolean;
    profile: Profile;
    login: LoginResponse;
    signUp: SignUpResponse;
    setSignUpByInvitation: SignUpResponse;
    resendCode: ResendCodeResponse;
    confirmEmail: ConfirmEmailResponse;
    signInGoogle: SignInGoogleResponse;
    generate2FA: Generate2FAResponse;
    authenticate2FA: Authenticate2FAResponse;
    //
    signUpEmail: SignUpEmail;
    resetPasswordEmail: ResetPasswordEmail;
    resetPasswordCode: ResetPasswordCode;
    callStatus: UserCallStatus;
    sipNumber: number | null;
    sipPassword: string | null;
}

const AuthModule = {
    namespaced: true,
    state: {
        isLoadingProfile: false,
        profile: undefined as unknown as Profile,
        login: undefined as LoginResponse,
        signUp: undefined as SignUpResponse,
        setSignUpByInvitation: undefined as SignUpResponse,
        resendCode: undefined as LoginResponse,
        confirmEmail: undefined as SignUpResponse,
        signInGoogle: undefined as SignInGoogleResponse,
        generate2FA: undefined as Generate2FAResponse,
        authenticate2FA: undefined as Authenticate2FAResponse,
        //
        signUpEmail: undefined as SignUpEmail,
        resetPasswordEmail: undefined as ResetPasswordEmail,
        resetPasswordCode: undefined as ResetPasswordCode,
        callStatus: undefined as unknown as UserCallStatus,
        sipNumber: null,
        sipPassword: null,
    },
    mutations: {
        setProfile: (state: AuthState, payload: Profile) => (state.profile = payload),
        setLogin: (state: AuthState, payload: LoginResponse) => (state.login = payload),
        setSignUp: (state: AuthState, payload: SignUpResponse) => (state.signUp = payload),
        setSignUpByInvitation: (state: AuthState, payload: SignUpResponse) => (state.signUp = payload),
        setResendCode: (state: AuthState, payload: ResendCodeResponse) => (state.resendCode = payload),
        setConfirmEmail: (state: AuthState, payload: ConfirmEmailResponse) => (state.confirmEmail = payload),
        setSignInGoogle: (state: AuthState, payload: SignInGoogleResponse) => (state.signInGoogle = payload),
        setGenerate2FA: (state: AuthState, payload: Generate2FAResponse) => (state.generate2FA = payload),
        setAuthenticate2FA: (state: AuthState, payload: Authenticate2FAResponse) => (state.authenticate2FA = payload),
        //
        setSignUpEmail: (state: AuthState, payload: string): void => {
            state.signUpEmail = payload;
        },
        setResetPasswordEmail: (state: AuthState, payload: string): void => {
            state.resetPasswordEmail = payload;
        },
        setResetPasswordCode: (state: AuthState, payload: string): void => {
            state.resetPasswordCode = payload;
        },
        setCallStatus: (state: AuthState, payload: UserCallStatus): void => {
            state.callStatus = payload;
        },
        setNotifyWithSound: (state: AuthState, payload: boolean): void => {
            state.profile.notifyWithSound = payload;
        },
        setProfileFetchingStatus: (state: AuthState, payload: boolean) => (state.isLoadingProfile = payload),
        setSipCredentials: (state: AuthState, payload: Profile) => {
            state.sipNumber = payload.sipNumber;
            state.sipPassword = payload.sipPassword;
        },
    },
    actions: {
        getProfile: async ({ commit }: Context) => {
            try {
                commit("setProfileFetchingStatus", true);
                const res = await authService.getProfile();
                commit("setProfile", res.data);
            } catch (error) {
                console.log(error); // WHAT NEXT?
            } finally {
                commit("setProfileFetchingStatus", false);
            }
        },
        getSipCredentials: async ({ commit }: Context) => {
            try {
                const res = await authService.getProfile();
                commit("setSipCredentials", res.data);
            } catch (error) {
                console.log(error);
            }
        },
        signUp: async ({ commit }: Context, payload: SignUp): Promise<void> => {
            commit("setSignUp", undefined);
            try {
                const res = await authService.signUp(payload);
                commit("setSignUp", res);
                commit("setSignUpEmail", payload.login);
            } catch (e: any) {
                commit("setSignUp", e.response || { statusText: e.code });
                throw e;
            }
        },
        checkSignUpInvitation: async ({ commit }: Context, payload: SignUpInvitationCheck) => {
            commit("setSignUp", undefined);
            try {
                const res = await authService.checkSignUpInvitation(payload);
                commit("setSignUp", res);
                commit("setSignUpEmail", payload.login);
            } catch (e: any) {
                commit("setSignUp", e.response || { statusText: e.code });
            }
        },
        signUpByInvitation: async ({ commit }: Context, payload: SignUpByInvitation): Promise<void> => {
            commit("setSignUpByInvitation", undefined);
            try {
                const res = await authService.signUpByInvitation(payload);
                commit("setSignUpByInvitation", res);
                commit("setSignUpEmail", payload.login);
            } catch (e: any) {
                commit("setSignUpByInvitation", e.response || { statusText: e.code });
            }
        },
        resendCode: async ({ commit }: Context, payload: ResendCode): Promise<void> => {
            commit("setResendCode", undefined);
            try {
                const res = await authService.resendCode(payload);
                commit("setResendCode", res);
            } catch (e: any) {
                commit("setResendCode", e.response || { statusText: e.code });
            }
        },
        confirmEmail: async ({ commit }: Context, payload: ConfirmEmail): Promise<void> => {
            commit("setConfirmEmail", undefined);
            try {
                const res = await authService.confirmEmail(payload);
                commit("setConfirmEmail", res);
            } catch (e: any) {
                commit("setConfirmEmail", e.response || { statusText: e.code });
                throw e;
            }
        },
        login: async ({ commit }: Context, payload: Login): Promise<void> => {
            commit("setLogin", undefined);
            try {
                const res = await authService.login(payload);
                commit("setLogin", res);
            } catch (e: any) {
                commit("setLogin", e.response || { statusText: e.code });
                throw e;
            }
        },
        resetPassword: async (_context: Context, login: string): Promise<void> => {
            try {
                await authService.resetPassword(login);
            } catch (e) {
                console.error(e);
            }
        },
        resetPasswordConfirmation: async (_context: Context, payload: ResetPasswordConfirmation): Promise<void> => {
            await authService.resetPasswordConfirmation(payload);
        },
        refreshToken: (_context: unknown) => authService.refreshToken(),
        logout: (_context: Context) => authService.logout(),
        signInGoogle: async ({ commit }: Context) => {
            commit("setSignInGoogle", undefined);
            try {
                const url = await authService.signInGoogleUrl();
                commit("setSignInGoogle", { url });
            } catch (e: any) {
                commit("setSignInGoogle", e.response || { statusText: e.code });
            }
        },
        authenticate2FA: async ({ commit }: Context, payload: TwoFaAuthenticate) => {
            commit("setAuthenticate2FA", undefined);
            try {
                const res = await authService.authenticate2FA(payload);
                commit("setAuthenticate2FA", res);
            } catch (e: any) {
                commit("setAuthenticate2FA", e.response || { statusText: e.code });
                throw e;
            }
        },
        setResetPasswordEmail: async ({ commit }: Context, payload: string) => {
            commit("setResetPasswordEmail", payload);
        },
        setResetPasswordCode: async ({ commit }: Context, payload: string) => {
            commit("setResetPasswordCode", payload);
        },
    },

    getters: {
        userProfile: (authState: AuthState) => authState.profile,
        userPermissions: (authState: AuthState) => authState?.profile?.role?.permissions ?? [],
    },
};

export const useResetPassword = () =>
    useMutation({
        mutationFn: (email: string) => authService.resetPassword(email),
    });

export const useResetPasswordCheckCode = () =>
    useMutation({
        mutationFn: (payload: ConfirmEmail) => authService.resetPasswordCheckCode(payload),
    });

export const useResetPasswordConfirmation = () =>
    useMutation({
        mutationFn: (payload: ResetPasswordConfirmation) => authService.resetPasswordConfirmation(payload),
    });

export const useUpdateProfile = () => {
    const queryClient = useQueryClient();
    const store = useStore<AuthState>();

    return useMutation({
        mutationFn: (payload: UpdateProfile) => authService.updateProfile(payload),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["getUser"] });
            await store.dispatch("auth/getProfile");
        },
    });
};

export const useSetLocal = () => {
    const store = useStore<AuthState>();

    return useMutation({
        mutationFn: (locale: "en-us" | "uk-ua") => authService.setLocale(locale),
        onSuccess: async () => {
            await store.dispatch("auth/getProfile");
        },
    });
};

export default AuthModule;
