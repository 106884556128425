import axios from "axios";
import Cookies from "js-cookie";
import { interceptResponseErrors } from "./interceptors";
import { useToast } from "vue-toastification";
const toast = useToast();

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    // exceeded timeout, because it seems some endpoints (like post custom_field) work extremely slow
    // extreme slowness should be fixed of cource, but at least let user do things
    timeout: 20000,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});

instance.interceptors.request.use((config) => {
    const disallowedAuthRoutes = ["/auth/centrifuge", "/auth/profile"];
    const whiteList = ["/statistics/dashboard", "/crm-settings"];
    if (config.url && !whiteList.includes(config.url) && (!config.url.startsWith("/auth") || disallowedAuthRoutes.includes(config.url))) {
        const refreshToken = Cookies.get("check");
        if (!refreshToken) {
            return Promise.reject(new axios.Cancel());
        }
    }
    return config;
});

instance.interceptors.response.use((response) => response, interceptResponseErrors);

export default instance;
