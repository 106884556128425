import { LocalizationStructure } from "@/uikit/utils/localization";

const uikit: LocalizationStructure = {
    components: {
        select: {
            placeholder: "Select",
        },
        searchInput: {
            placeholder: "Search",
        },
        dateRange: {
            range: "Range",
            cancel: "Clear filters",
            apply: "Apply",
            today: "Today",
            yesterday: "Yesterday",
            thisWeek: "This week",
            thisMonth: "This month",
            thisYear: "This year",
        },
        date: {
            minDateError: "This date is less than minimum. Input correct date",
            maxDateError: "This date is more than maximum. Input correct date",
            incorrectDateError: "Incorrect date inputted. Check day/month/year values.",
            dd: "dd",
            mm: "mm",
            yyyy: "yyyy",
            clearDate: "Clear date",
        },
        numberRange: {
            from: "From",
            to: "To",
        },
    },
    locale: "en",
    fullLocale: "en-us",
};

export default uikit;
