import { enUsLocaleName, ukUaLocaleName } from "@/utils/constants";
import Joi from "joi";
import { noEmptyString, referenceSchema } from "../common";

const nameErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Назва завдання не може бути порожня.",
        "string.max": "Назва завдання не може містити більше 100 символів.",
        "string.min": "Назва завдання не може містити менше 3 символів.",
    },
    [enUsLocaleName]: {
        "string.empty": "The task name cannot be empty.",
        "string.max": "The task name cannot be longer than 100 characters.",
        "string.min": "The task name cannot be shorter than 3 characters.",
    },
};

const responsibleIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "У завдання має бути призначено головного відповідального.",
        "number.positive": "У завдання має бути призначено головного відповідального.",
        "number.integer": "У завдання має бути призначено головного відповідального.",
        "any.required": "У завдання має бути призначено головного відповідального.",
    },
    [enUsLocaleName]: {
        "number.base": "The task must have a main responsible person assigned.",
        "number.positive": "The task must have a main responsible person assigned.",
        "number.integer": "The task must have a main responsible person assigned.",
        "any.required": "The task must have a main responsible person assigned.",
    },
};

const createdByErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "У завдання має бути призначено автора.",
        "number.positive": "У завдання має бути призначено автора.",
        "number.integer": "У завдання має бути призначено автора.",
        "any.required": "У завдання має бути призначено автора.",
    },
    [enUsLocaleName]: {
        "number.base": "The task must have a creator person assigned.",
        "number.positive": "The task must have a creator person assigned.",
        "number.integer": "The task must have a creator person assigned.",
        "any.required": "The task must have a creator person assigned.",
    },
};

const statusIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "У завдання має бути статус.",
        "number.positive": "У завдання має бути статус.",
        "number.integer": "У завдання має бути статус.",
        "any.required": "У завдання має бути статус.",
    },
    [enUsLocaleName]: {
        "number.base": "The task must have a creator status.",
        "number.positive": "The task must have a creator status.",
        "number.integer": "The task must have a creator status.",
        "any.required": "The task must have a creator status.",
    },
};

export const taskRequiredFields: string[] = ["name", "createdBy", "responsible", "status"];

const commonFields = Joi.object({
    name: noEmptyString.min(3).max(100).required().messages(nameErrMsgs),
    comment: noEmptyString.allow("", null).optional(),
    endDate: Joi.date().optional(),
    createdAt: Joi.date(),
});

export const createTaskSchema = commonFields.append({
    responsibleContactId: Joi.number().integer().allow(-1, null).optional(),
    createdById: Joi.number().integer().positive().required().messages(createdByErrMsgs),
    statusId: Joi.number().integer().positive().required().messages(statusIdErrMsgs),
    clientId: Joi.number().integer().positive().optional().allow(0, null),
    leadId: Joi.number().integer().positive().optional().allow(0, null),
    dealId: Joi.number().integer().optional().allow(-1, null),
    responsibleId: Joi.number().integer().positive().required().messages(responsibleIdErrMsgs),
});

export const updateTaskSchema = commonFields.append({
    responsibleContact: Joi.object(referenceSchema),
    createdBy: Joi.object(referenceSchema),
    status: Joi.object(referenceSchema),
    client: Joi.object(referenceSchema),
    lead: Joi.object(referenceSchema),
    deal: Joi.object(referenceSchema),
    responsible: Joi.object(referenceSchema).messages(responsibleIdErrMsgs),
});
