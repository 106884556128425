import router from "../router";

export const getFieldLabel = (requiredField: string[], label: string, field?: string) => `${label}${field && requiredField.includes(field) ? " *" : ""}`;

export const viewDuplicatedInfoPage = async (errorData: { lead?: number; client?: number; priceList?: number }) => {
    console.log("errorData", errorData);
    let routeLocation = undefined;
    if (errorData?.lead) {
        routeLocation = { name: "LeadInfo", params: { id: errorData.lead } };
    } else if (errorData?.client) {
        routeLocation = { name: "ClientInfo", params: { id: errorData.client } };
    } else if (errorData?.priceList) {
        routeLocation = { name: "PriceListInfo", params: { id: errorData.priceList } };
    }
    if (!routeLocation) {
        return;
    }
    const { href } = router.resolve(routeLocation);
    window.open(href, "_blank");
};

export const emptyOption = (t: (_: string) => string) => ({
    name: t("inputs.selectInput.emptyValue"),
    value: null,
});
