import { ref, watch } from "vue";
import { Ref } from "vue/dist/vue";

interface ExtendedAudio extends HTMLAudioElement {
    currentRecord?: string;
}

export const useAudioPlayer = () => {
    const audio: Ref<ExtendedAudio> = ref(new Audio() as ExtendedAudio);
    const playingRecords = ref<Record<string, boolean>>({});
    const volume = ref(1);

    watch(volume, (newVolume) => {
        audio.value.volume = newVolume;
    });

    const playAudio = (audioSource: string, recordId: string) => {
        if (audio.value.paused || audio.value.src !== audioSource) {
            if (!audio.value.paused && audio.value.currentRecord) {
                playingRecords.value[audio.value.currentRecord] = false;
            }
            audio.value.src = audioSource;
            audio.value
                .play()
                .then(() => {
                    playingRecords.value[recordId] = true;
                    audio.value.currentRecord = recordId;
                })
                .catch((error) => {
                    console.error("Failed to play audio:", error);
                });
        } else {
            audio.value.pause();
            playingRecords.value[recordId] = false;
        }
    };

    audio.value.addEventListener("ended", () => {
        if (audio.value.currentRecord) {
            playingRecords.value[audio.value.currentRecord] = false;
        }
    });

    return {
        playAudio,
        playingRecords,
        volume,
    };
};
