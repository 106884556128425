const validationsErrors = {
    invalidFieldsTemplateSing: "The field {fields} is invalid, please check it",
    invalidFieldsTemplatePlur: "The fields {fields} are invalid, please check them",
    auth: {
        invalidFields: {
            fullName: "Name and surname",
            password: "Think up password",
            phoneNumber: "Your phone number",
            confirmPassword: "Think up password",
            login: "Your email",
        },
    },
    lead: {
        invalidFields: {
            responsible: "responsible person",
            typeId: "lead type",
            name: "name",
            novaPostPostDepartmentType: "department type",
            novaPostPostDepartment: "post office",
            phoneNumber: "phone number",
            email: "email",
            contactPerson: "contact person",
            statusId: "status",
            comment: "comment",
        },
    },
    createLead: {
        invalidFields: {
            responsibleId: "responsible person",
            typeId: "lead type",
            name: "name",
            novaPostPostDepartmentType: "department type",
            novaPostPostDepartment: "post office",
            phoneNumber: "phone number",
            email: "email",
            contactPerson: "contact person",
            statusId: "status",
            comment: "comment",
        },
    },
    client: {
        invalidFields: {
            source: "source",
            email: "email",
            name: "Name / Company Name",
            phoneNumber: "phone number",
            novaPostPostDepartmentType: "department type",
            novaPostPostDepartment: "post office",
            type: "type",
            status: "status",
            responsible: "responsible",
            contactPerson: "contact person",
            comment: "comment",
        },
    },
    deal: {
        invalidFields: {
            currencyId: "currency",
            currency: "currency",
            name: "deal name",
            amount: "amount",
            responsibleId: "responsible person",
            clientId: "client",
            stageId: "stage",
            comment: "comment",
        },
    },
    task: {
        invalidFields: {
            name: "task name",
            responsibleId: "main responsible",
            statusId: "status",
            createdById: "creator",
            clientId: "client id",
            leadId: "lead id",
            dealId: "deal id",
            comment: "comment",
        },
    },
    priceList: {
        invalidFields: {
            name: "price list name",
            unit: "unit of measurement",
            description: "description",
            cost: "cost",
            price: "price",
            url: "URL",
            currencyId: "currency",
            article: "article",
        },
    },
    user: {
        invalidFields: {
            fullName: "Full Name",
            login: "Email",
            roleId: "Role",
            positionId: "Position",
            confirmPassword: "Confirm Password",
        },
    },
    additionalContacts: {
        invalidFields: {
            phoneNumber: "Phone number",
            email: "Email",
            firstName: "Name",
            lastName: "Last name",
            middleName: "Middle name",
        },
    },
};

export default validationsErrors;
