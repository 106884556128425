import { ActionContext } from "vuex";

import { clientsService } from "@/services";
import { AxiosResponse } from "axios";
import { UpdateClient, Client, ClientFilters, ClientSorting, ClientList, Locale, Pagination } from "@/services/types";
import { paginationState, paginationMutations, PaginationState, customFieldsState, customFieldsMutations, CustomFieldsState } from "@/store/mixins";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { computed, ComputedRef } from "vue";
import { DownloadFileTypeOptions, ImportData } from "@/utils/import-export";

type ClientResponse = AxiosResponse<{ _: string }> | undefined;

export interface ClientsState extends CustomFieldsState, PaginationState {
    createClient: ClientResponse;
    updateClient: ClientResponse;
    selectInputOptions: Partial<Client>[];
    clients: Client[];
    client: Client;
    additionalContactOptions: any[];
}

const ClientsModule = {
    namespaced: true,
    state: {
        ...customFieldsState,
        ...paginationState,
        createClient: undefined as ClientResponse,
        updateClient: undefined as ClientResponse,
        selectInputOptions: [] as Partial<Client>[],
        clients: [] as Client[],
        client: undefined,
        additionalContactOptions: [],
    },
    mutations: {
        ...customFieldsMutations,
        ...paginationMutations,
        setCreateClient: (state: ClientsState, payload: ClientResponse): void => {
            state.createClient = payload;
        },
        setUpdateClient: (state: ClientsState, payload: ClientResponse): void => {
            state.updateClient = payload;
        },
        setClients: (state: ClientsState, payload: Client[]): void => {
            state.clients = payload;
        },
        setClient: (state: ClientsState, payload: Client): void => {
            state.client = payload;
        },
        setClientsForSelectInput: (state: ClientsState, payload: Partial<Client>[]): void => {
            state.selectInputOptions = payload;
        },
        setAdditionalContacts: (state: ClientsState, payload: any): void => {
            state.additionalContactOptions = payload;
        },
    },
    actions: {
        clientList: async ({ commit }: ActionContext<unknown, unknown>, filters: ClientFilters | ClientSorting | Locale): Promise<void> => {
            try {
                const { clients, customFields, page, pagesCount }: ClientList = await clientsService.clientList(filters);
                commit("setClients", clients);
                commit("setCustomFields", customFields);
                commit("setPagesCount", pagesCount);
                commit("setCurrentPage", page);
            } catch (e) {
                console.error(e);
            }
        },
        getClient: async ({ commit }: ActionContext<unknown, unknown>, { id, locale }: { id: string; locale: Locale }) => {
            const client = await clientsService.getClient(id, locale);
            commit("setClient", client);
            return client;
        },
        getSelectInputOptions: async ({ commit }: ActionContext<unknown, unknown>): Promise<void> => {
            try {
                const clients = await clientsService.someFields({ fields: ["id", "name"] });
                commit("setClientsForSelectInput", clients);
            } catch (e) {
                console.error(e);
            }
        },
        createClient: async (
            { commit }: ActionContext<unknown, unknown>,
            { id, statusId, locale }: { id: number; statusId: number | undefined; locale: Locale }
        ): Promise<void> => {
            commit("setCreateClient", undefined);
            try {
                const res = await clientsService.createClient(id, statusId, locale as unknown as "en" | "ua");
                commit("setCreateClient", res);
            } catch (e: any) {
                commit("setCreateClient", e.response || { statusText: e.code });
                throw e;
            }
        },
        updateClient: async (
            { commit }: ActionContext<unknown, unknown>,
            { id, payload, locale }: { id: number; payload: UpdateClient; locale: Locale }
        ): Promise<void> => {
            commit("setUpdateClient", undefined);
            try {
                const res = await clientsService.updateClient(id, payload, locale);
                commit("setUpdateClient", res);
            } catch (e: any) {
                commit("setUpdateClient", e.response || { statusText: e.code });
                throw e;
            }
        },
        async bulkUpdate(_commit: unknown, { data, locale }: { data: UpdateClient; locale: Locale }): Promise<void> {
            try {
                return clientsService.bulkUpdate(data, locale);
            } catch (e) {
                console.error(e);
            }
        },
        async addContact(_commit: unknown, { id, email }: { id: number; email: string }): Promise<void> {
            try {
                await clientsService.addContact(id, email);
            } catch (e) {
                console.error(e);
            }
        },
        getClientAdditionalContactOptions: async ({ commit }: ActionContext<unknown, unknown>, clientId: number): Promise<void> => {
            try {
                const additionalContacts: ClientList = await clientsService.getClientAdditionalContactOptions(clientId);
                commit("setAdditionalContacts", additionalContacts);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

export const useClientFields = ({ locale, enabled }: { locale: "en" | "ua"; enabled: ComputedRef<boolean> } = { locale: "ua", enabled: computed(() => true) }) =>
    useQuery({
        queryKey: ["clients/fields"],
        queryFn: () => clientsService.clientFieldsList(locale).then((res) => res.data),
        enabled,
    });

export const useExport = () =>
    useMutation({
        mutationFn: (
            filters: { locale: "ua" | "en" } & {
                essenceFieldIds: string[];
                customFieldIds: number[];
                additionalContactsFieldIds: string[];
                fileType: DownloadFileTypeOptions;
            } & Omit<ClientFilters, keyof Pagination> &
                ClientSorting
        ) => clientsService.exportClients(filters).then((res) => res.data),
    });

export const useImport = () =>
    useMutation({
        mutationFn: (data: ImportData) => clientsService.importClients(data).then((res) => res.data),
    });

export const useImportTemplate = () =>
    useMutation({
        mutationFn: (fileType: DownloadFileTypeOptions) => clientsService.importClientsTemplate(fileType).then((res) => res.data),
    });

export const useCreateClient = () =>
    useMutation({
        mutationFn: (data: { leadId: number; statusId?: number; locale: "en" | "ua" }) =>
            clientsService.createClient(data.leadId, data.statusId, data.locale).then((res) => res.data),
    });

export default ClientsModule;
