const profile = {
    title: "Особистий кабінет",
    user: "Користувач",
    inputs: {
        fullName: "Ім’я та прізвище",
        login: "Ваша пошта",
        phoneNumber: "Ваш номер телефону",
        phoneCountry: "Країна телефону",
        password: "Ваш пароль",
        passwordHint: "Ви можете змінити пароль використав форму знизу",
        newPassword: "Новий пароль",
        passwordRequirements: "Мінімум 8 символів, 1 велика літера, 1 маленька",
        newPasswordConfirm: "Повторіть новий пароль",
    },
    save: "Зберегти зміни",
    deletePhoto: "Видалити фото",
    editPhoto: "Замінити фото",
    addPhoto: "Додати фото",
    cancel: "Відмінити",
    newPassword: "Встановити новий пароль",
    changePassword: "Змінити пароль",
};
export default profile;
