import { Email, Log } from "@/services/types";
import { RootState } from "..";
import { ActionContext, Store } from "vuex";

type Context = ActionContext<any, Store<RootState>>;
const LOCAL_STORAGE_KEY = "notifications";

export interface NotificationsState {
    pull: (Email | Log)[];
    isUnread: boolean;
    userId?: number;
}

const getStageFromLocalStorage = (userId = 0): NotificationsState => {
    const data = window.localStorage.getItem(`${LOCAL_STORAGE_KEY}:${userId}`);
    try {
        if (data) {
            return {
                ...JSON.parse(data),
                userId,
            };
        }
    } catch {
        window.localStorage.removeItem(`${LOCAL_STORAGE_KEY}:${userId}`);
    }
    return {
        pull: [],
        isUnread: false,
        userId: userId,
    };
};

const saveStateToLocalStorage = (state: NotificationsState): void => {
    if (!state.userId) {
        return;
    }
    window.localStorage.setItem(`${LOCAL_STORAGE_KEY}:${state.userId}`, JSON.stringify(state));
};

const LogsModule = {
    namespaced: true,
    state: getStageFromLocalStorage(),
    actions: {
        fetch: ({ commit, state }: Context, userId: number): void => {
            const loadedState = getStageFromLocalStorage(userId);
            state.userId = userId;
            commit("set", loadedState.pull);
        },
    },
    mutations: {
        add: (state: NotificationsState, payload: (Email | Log)[]): void => {
            state.pull = [...payload, ...state.pull].slice(0, 10).filter(Boolean);
            state.isUnread = state.pull.length > 0;
            saveStateToLocalStorage(state);
        },

        set: (state: NotificationsState, payload: (Email | Log)[]): void => {
            state.pull = payload;
            state.isUnread = state.pull.length > 0;
            saveStateToLocalStorage(state);
        },

        remove: (state: NotificationsState, index: number): void => {
            state.pull.splice(index, 1);
            state.isUnread = state.pull.length > 0;
            saveStateToLocalStorage(state);
        },

        removeAll: (state: NotificationsState): void => {
            state.pull = [];
            state.isUnread = false;
            saveStateToLocalStorage(state);
        },

        markAsViewed: (state: NotificationsState) => {
            state.isUnread = state.pull.length > 0;
            saveStateToLocalStorage(state);
        },
    },
};

export default LogsModule;
