const profile = {
    title: "Profile",
    user: "User",
    inputs: {
        fullName: "Full name",
        login: "Your email",
        phoneNumber: "Your phone number",
        phoneCountry: "Phone country",
        password: "Your password",
        passwordHint: "You can change the password using the form below",
        newPassword: "New password",
        passwordRequirements: "Minimum 8 characters, 1 uppercase letter, 1 lowercase letter",
        newPasswordConfirm: "Repeat new password",
    },
    save: "Save changes",
    deletePhoto: "Delete photo",
    editPhoto: "Edit photo",
    addPhoto: "Add photo",
    cancel: "Cancel",
    newPassword: "Set new password",
    changePassword: "Change password",
};
export default profile;
