const priceLists = {
    title: "Прайсліст",
    subtitle: "Відслідковуй найголовніше на Дешборді",
    table: {
        title: "Послуги/товари",
        emptyMessage: "Таблиця не містить даних",
        emptySearchResult: "Даних не знайдено за вашим запитом",
        panel: {
            searchPlaceholder: "Пошук",
            archive: "Архів",
            addListItem: "Товар/послуга",
            addCategory: "Додати категорію",
            filter: "Фільтр",
        },
        columns: {
            id: "ID",
            category: "Категорія",
            photo: "Фото",
            photoUrl: "Фото",
            article: "Артикул",
            name: "Назва",
            unit: "Одиниця виміру",
            cost: "Собівартість",
            price: "Ціна",
            currency: "Валюта",
            url: "Url",
            actions: "Дії",
            newCategoryName: "Нова категорія",
            amount: "Кількість",
            mainPhoto: "Головне фото",
            description: "Опис",
            priceListAmount: "Кількість",
            createdAt: "Дата створення",
            barcode: "Штрихкод",
            barcodeFormat: "Формат штрихкоду",
            barcodeComment: "Коментар до штрихкоду",
            barcodeImg: "Картинка штрихкоду",
            barcodePdf: "Штрихкод PDF",
        },
    },

    barcodes: {
        actionMenu: {
            update: "Редагувати",
            downloadPdf: "Завантажити PDF",
            delete: "Видалити",
        },
        table: {
            title: "Штрихкоди",
            addBarcode: "Додати",
            emptyMessage: "Таблиця не містить даних",
            columns: {
                id: "ID",
                barcode: "Штрихкоди",
                barcodeImg: "Фото",
                barcodeFormat: "Символіка",
                barcodeComment: "Коментар",
            },
        },

        modal: {
            upsertBarcode: {
                newBarcodeTitle: "Новий штрихкод",
                updateBarcodeTitle: "Штрихкод {barcode}",
                inputs: {
                    modification: "Модифікація",
                    selectModification: "Виберіть модифікацію",
                    barcode: "Штрихкод",
                    barcodePlaceholder: "Введіть штрихкод",
                    format: "Символіка",
                    comment: "Коментар",
                    commentPlaceholder: "Напишіть коментар",
                },
            },
        },
    },

    modal: {
        header: "Створити товар / послугу",
        inputs: {
            category: "Категорія",
            photo: "Фото",
            photoPlaceholder: "Завантажити фото",
            article: "Артикул",
            articleError: "Артикул не коректний",
            articlePlaceholder: "Введіть акртикул товару, послуги",
            name: "Назва",
            description: "Опис",
            nameError: "Назва не коректна",
            namePlaceholder: "Введіть назву товару, послуги",
            descriptionPlaceholder: "Введіть опис товару, послуги",
            unit: "Одиниця виміру",
            unitError: "Одиниця виміру не коректна",
            unitPlaceholder: "Введіть одиницю виміру",
            cost: "Собівартість",
            costError: "Собівартість не коректна",
            costPlaceholder: "Введіть собівартість товару, послуги",
            price: "Ціна",
            priceError: "Ціна не коректна",
            pricePlaceholder: "Введіть ціну товару, послуги",
            currency: "Валюта",
            url: "URL",
            urlError: "Будь ласка, введіть дійсну URL-адресу (наприклад, example.com, www.example.com).",
            urlPlaceholder: "Введіть посилання на товар, послугу",
        },
        create: "Створити",
        createError: "Помилка створення товару, послуги",
    },
    filter: {
        header: "Фільтр",
        inputs: {
            article: "Артикул",
            currency: "Валюта",
            name: "Назва",
            cost: "Собівартість",
            price: "Ціна",
        },
        validationMessages: {
            cost: "Вартість має бути позитивним числом",
            costRangeError: "Вартість від має бути меншою, ніж до",
            price: "Ціна повинна бути позитивним числом",
            priceRangeError: "Ціна від повинна бути меншою, ніж до",
        },
        placeholders: {
            currency: "Введіть валюту товару, послуги",
        },
        apply: "Застосувати",
        clear: "Скинути",
    },
    card: {
        title: "Особиста картка товару/послуги",
        info: {
            title: "Інформація про товар/послугу",
            saveButton: "Зберегти",
        },
    },
    categoryList: {
        select: "Обрати",
        selected: "Обрано",
        open: "Відкрити",
        opened: "Відкрито",
        items: "елементів",
    },
    addToDeal: {
        selected: "Обране",
        addAll: "Зберегти все обране",
        modal: "Додати або змінити обрані товари/послуги",
        selectedTableHint: "Оберіть товари/послуги зверху, щоб вони зʼявились тут",
        errorMessage: "Виникла помилка при додаванні товарів/послуг",
        missmatchingCurrencyError: "Ви можете додавати товари/послуги тільки у валюті угоди",
    },
    logs: {
        newPhotos: "Додані нові фото",
        deletedPhotos: "Видалені фото",
    },
};

export default priceLists;
