import { ref, onMounted, onUnmounted, computed } from "vue";
import { newUUID } from "jssip/lib/Utils";
import moment from "moment";
import { useRootStore } from "@/utils/hooks/store";

export function useActiveTab() {
    const date = moment().format("YYYY-MM-DD");
    const tabId = `${date}-${newUUID()}`;

    const currentTabId = ref(localStorage.getItem("activeTabId") || null);
    const isActiveTab = computed(() => currentTabId.value === tabId);

    const channel = new BroadcastChannel("tabsChannel");
    const store = useRootStore();

    const notifyTabs = () => {
        channel.postMessage({
            type: "update-tabs",
            activeTabId: localStorage.getItem("activeTabId") || tabId,
        });
    };

    const setActiveTab = () => {
        localStorage.setItem("activeTabId", tabId);
        currentTabId.value = tabId;
        notifyTabs();
        store.commit("modals/setParams", { initSipConnection: true, closeSipConnection: false });
    };

    const updateActiveTabIfNeeded = () => {
        const storedTabs = JSON.parse(localStorage.getItem("tabs") || "[]");
        const activeTab = localStorage.getItem("activeTabId");

        if (!activeTab || !storedTabs.includes(activeTab)) {
            setActiveTab();
        }
    };

    channel.onmessage = (event) => {
        const { type, activeTabId } = event.data;
        if (type === "update-tabs") {
            if (!(document.visibilityState === "visible" && document.hasFocus())) {
                currentTabId.value = activeTabId;
                if (currentTabId.value !== tabId) {
                    if (!store.state.calls.hasActiveCallSession && !store.state.calls.isDialing) {
                        store.commit("modals/setParams", { closeSipConnection: true, initSipConnection: false });
                    } else {
                        console.log("Call in progress; not closing SIP in this tab.");
                    }
                } else {
                    store.commit("modals/setParams", { initSipConnection: true, closeSipConnection: false });
                }
            }
        }
    };

    const handleTabClose = () => {
        store.commit("modals/setParams", { closeSipConnection: true, initSipConnection: false });
        const storedTabs = JSON.parse(localStorage.getItem("tabs") || "[]");
        const updatedTabs = storedTabs.filter((tab: string) => tab !== tabId && tab.startsWith(date));
        if (updatedTabs.length > 0) {
            localStorage.setItem("tabs", JSON.stringify(updatedTabs));
            if (isActiveTab.value) {
                localStorage.setItem("activeTabId", updatedTabs[updatedTabs.length - 1]);
            }
            notifyTabs();
        } else {
            localStorage.removeItem("tabs");
            localStorage.removeItem("activeTabId");
        }
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === "visible" && document.hasFocus()) {
            updateActiveTabIfNeeded();
        }
    };

    onMounted(() => {
        const storedTabs = JSON.parse(localStorage.getItem("tabs") || "[]");
        if (!storedTabs.includes(tabId)) {
            storedTabs.push(tabId);
            localStorage.setItem("tabs", JSON.stringify(storedTabs));
        }

        // Immediately set the new tab as active regardless of visibility/focus.
        setActiveTab();

        window.addEventListener("focus", updateActiveTabIfNeeded);
        window.addEventListener("beforeunload", handleTabClose);
        document.addEventListener("visibilitychange", handleVisibilityChange);
    });
    onUnmounted(() => {
        window.removeEventListener("focus", updateActiveTabIfNeeded);
        window.removeEventListener("beforeunload", handleTabClose);
        document.removeEventListener("visibilitychange", handleVisibilityChange);
    });

    return isActiveTab;
}
