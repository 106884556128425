import i18n from "@/i18n";

export const errMsgRetriever = (e: any, withFallbackMessage = true) => {
    if (!e) return null;

    const message = e.response?.data?.message;
    if (message) {
        return Array.isArray(message) ? message.join(", ") : message;
    } else if (withFallbackMessage) {
        return i18n.global.t("errors.smthWentWrong");
    } else {
        return null;
    }
};
