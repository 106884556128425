import { enUsLocaleName, ukUaLocaleName } from "@/utils/constants";
import Joi from "joi";
import { filterNumber, noEmptyString } from "../common";

const nameErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Назва прайсліста не може бути порожня.",
        "string.max": "Назва прайсліста не може містити більше 100 символів",
        "string.min": "Назва прайсліста не може містити менше 3 символів",
    },
    [enUsLocaleName]: {
        "string.empty": "The price list name cannot be empty.",
        "string.max": "The price list name cannot exceed 100 characters.",
        "string.min": "The price list name must be at least 3 characters long.",
    },
};

const descriptionErrMsgs = {
    [ukUaLocaleName]: {
        "string.max": "Опис прайсліста не може містити більше 100 символів",
    },
    [enUsLocaleName]: {
        "string.max": "The price list description cannot exceed 100 characters.",
    },
};

const unitsErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": 'Поле "Одиниця виміру" є обовʼязковим.',
        "string.min": 'Поле "Одиниця виміру" не може містити менше 1 символа.',
        "string.max": 'Поле "Одиниця виміру" не може містити більше 20 символів.',
        "any.required": 'Поле "Одиниця виміру" є обовʼязковим.',
    },
    [enUsLocaleName]: {
        "string.empty": 'The field "unit of measurements" is required.',
        "string.min": 'The field "unit of measurements" must be at least 1 character long.',
        "string.max": 'The field "unit of measurements" cannot exceed 20 characters.',
        "any.required": 'The field "unit of measurements" is required.',
    },
};

const articleErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": 'Поле "Артикул" є обовʼязковим.',
        "string.min": 'Поле "Артикул" не може містити менше 1 символа.',
        "any.required": 'Поле "Артикул" є обовʼязковим.',
    },
    [enUsLocaleName]: {
        "string.empty": 'The field "article" is required.',
        "string.min": 'The field "article" must be at least 1 character long.',
        "any.required": 'The field "article" is required.',
    },
};

const costAndPriceErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "Поле повинно бути числом.",
        "number.min": "Поле повинно бути не менше 0.01.",
        "number.positive": "Поле повинно бути більшим за 0.",
        "number.integer": "Поле повинно бути цілим числом.",
        "number.precision": "Поле повинно мати не більше 15 десяткових знаків.",
        "any.required": "Поле є обовʼязковим.",
    },
    [enUsLocaleName]: {
        "number.base": "The field must be a number.",
        "number.min": "The field must be at least 0.01.",
        "number.positive": "The field must be greater than 0.",
        "number.integer": "The field must be an integer.",
        "number.precision": "The field must have no more than 15 decimal places.",
        "any.required": "The field is required.",
    },
};

const urlErrMsgs = {
    [ukUaLocaleName]: {
        "string.pattern.base": "Будь ласка, введіть правильний формат URL, наприклад, починаючи з https://, http:// або www.",
    },
    [enUsLocaleName]: {
        "string.pattern.base": "Please enter a correct URL format, such as starting with https://, http://, or www.",
    },
};

const currencyErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "валюта" є обовʼязковим.',
        "number.integer": 'Поле "валюта" є обовʼязковим.',
        "number.positive": 'Поле "валюта" є обовʼязковим.',
        "any.required": 'Поле "валюта" є обовʼязковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "currency" field is required.',
        "number.integer": 'The "currency" field is required.',
        "number.positive": 'The "currency" field is required.',
        "any.required": 'The "currency" field is required.',
    },
};

export const pricelistRequiredFields = ["name", "article", "currency", "unit", "cost", "price"];

const commonFields = Joi.object({
    name: noEmptyString.min(3).max(100).required().messages(nameErrMsgs),
    description: Joi.string().max(100).allow("", null).optional().messages(descriptionErrMsgs),
    categoryId: Joi.number().min(0).optional().allow(0, null),
    file: Joi.any().optional(),
    article: noEmptyString.min(1).required().messages(articleErrMsgs),
    unit: noEmptyString.min(1).max(20).required().messages(unitsErrMsgs),
    cost: Joi.number().min(0.01).precision(15).required().messages(costAndPriceErrMsgs),
    price: Joi.number().min(0.01).precision(15).required().messages(costAndPriceErrMsgs),
    createdAt: Joi.date().optional(),
});

const urlSchema = Joi.string()
    .pattern(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/)
    .allow("")
    .optional()
    .messages(urlErrMsgs);

export const createPriceListSchema = commonFields.append({
    url: urlSchema,
    currencyId: Joi.number().integer().positive().required().messages(currencyErrMsgs),
});

export const updatePriceListSchema = commonFields.append({
    url: urlSchema.allow(null),
    currencyId: Joi.number().optional().positive().messages(currencyErrMsgs),
});

export const priceListFiltersSchema = Joi.object({
    priceList_costFrom: filterNumber,
    priceList_costTo: filterNumber,
    priceList_priceFrom: filterNumber,
    priceList_priceTo: filterNumber,
});

export * from "./barcodes";
