const dashboard = {
    title: "Дешборд",
    subtitle: "Відслідковуй найголовніше на Дешборді",
    totalRange: "Загальний діапазон",

    leadCount: {
        title: "Активність продажів",
        subtitle: "Відслідковуй кількість лідів, клієнтів та угод",
        counts: {
            lead: "Ліди",
            client: "Клієнти",
            deal: "Угоди",
        },
    },

    callCount: {
        title: "Кількість дзвінків",
        subtitle: "Відслідковуй кількість вхідних, вихідних та пропущених дзвінків за фільтром",
        counts: {
            incoming: "Вхідні дзвінки",
            outgoing: "Вихідні дзвінки",
            missed: "Пропущені дзвінки",
            all: "Усі дзвінки",
        },
    },

    leadPercentage: {
        title: "Структура нових даних",
        subtitle: "Відслідковуй розподіл лідів, клієнтів та угод",
        noData: "Дані відсутні за цей період",
        counts: {
            label: "Кількість",
            leads: "Ліди",
            clients: "Клієнти",
            deals: "Угоди",
        },
    },
};

export default dashboard;
