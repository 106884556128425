import api from "@/services/api";
import { CallFilters, CallSorting, RedirectCall, ToggleHoldCall } from "@/services/types";
import { CallList } from "@/services/types/calls/call-list";
import { UpdateCall } from "@/services/types/calls/update-call";
import { CreateCall } from "@/services/types/calls/create-call";

export const callsService = {
    async callList(filters: CallFilters | CallSorting): Promise<CallList> {
        const response = await api.get("/calls", {
            params: filters,
        });
        return response.data;
    },

    async internalNumbersList() {
        const response = await api.get("/calls/internal-numbers", {});
        return response.data;
    },

    async createCall(call: CreateCall) {
        await api.post("/calls", call);
    },

    async getRecordCallUrl(requestId: string) {
        const response = await api.get("/calls/record-url", {
            params: { requestId },
        });
        return response.data;
    },

    async updateCall(id: string, call: UpdateCall) {
        return api.patch(`/calls/${id}`, call);
    },

    async requestCallsArchive(callIds: number[]): Promise<void> {
        await api.post("/calls/archive", {
            callIds,
        });
    },

    async toggleHold(payload: ToggleHoldCall): Promise<void> {
        await api.post("/calls/toggle-hold", payload);
    },

    async redirect(payload: RedirectCall): Promise<void> {
        await api.post("/calls/redirect", payload);
    },
};
