import api from "@/services/api";
import { User, CreateUser, UpdateUser, SomeFieldsRequest, UserFilters, UserSearch } from "@/services/types";

export const usersService = {
    async userList(params: UserFilters): Promise<{ users: User[]; totalCount: number }> {
        const response = await api.get("/users", { params });
        return response.data;
    },

    async deleteUser(userId: number) {
        const response = await api.delete(`/users/${userId}`);
        return response.data;
    },

    async someFields(params: SomeFieldsRequest): Promise<Partial<User>[]> {
        const response = await api.get("/users/some-fields", {
            params: { fields: params.fields?.join(",") },
        });
        return response.data;
    },

    async createUser(payload: CreateUser): Promise<void> {
        const response = await api.post("/users", payload);
        return response.data;
    },
    async updateUser(id: number, payload: UpdateUser): Promise<void> {
        const formData = new FormData();

        Object.entries(payload).forEach(([key, value]) => formData.append(key, value as string));

        const response = await api.patch(`/users/${id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 1000 * 60 * 20,
        });

        return response.data;
    },
    async removeUser(id: number): Promise<void> {
        const response = await api.delete(`/users/${id}`);
        return response.data;
    },

    async getUserById(id: number): Promise<User> {
        const response = await api.get(`/users/${id}`);
        return response.data;
    },

    async findUser(params: UserSearch): Promise<any> {
        const response = await api.get("/users/search", {
            params,
        });
        return response.data;
    },
};
