import { ref, computed, watch } from "vue";
import { RTCSession } from "jssip/lib/RTCSession";
import JsSIP from "jssip";
import { useRootStore } from "@/utils/hooks";
import { usersService, callsService } from "@/services";
import { referencesService } from "@/services";
import { useI18n } from "vue-i18n";
import { UserCallStatus } from "@/services/enums/user-call-status.enum";
import { useToast } from "vue-toastification";
import { callRoles } from "@/services/enums/call-roles.enum";

export function useCallSession(sipOptions: any, getSessionRef: () => RTCSession | null = () => null) {
    const store = useRootStore();
    const toast = useToast();
    const { t } = useI18n();

    const currentCallRole = ref(callRoles.callee);

    const hasActiveCallSession = computed(() => store.state.calls.hasActiveCallSession);
    const isDialing = computed(() => store.state.calls.isDialing);
    const isDtmfMode = computed(() => store.state.calls.isDtmfMode);
    const dtmfTone = computed(() => store.state.calls.dtmfTone);
    const phoneNumber = computed(() => store.state.calls.phoneNumber);
    const remotePhoneNumber = computed(() => store.state.calls.remotePhoneNumber);
    const transferToNumber = computed(() => store.state.calls.transferToNumber);
    const isCallOnHold = computed(() => store.state.calls.isOnHold);
    const isCallMuted = computed(() => store.state.calls.isCallMuted);

    const subscriberIsBusy = ref(false);
    const remoteIsInternal = ref(false);
    const prevNumber = ref("");

    const remoteUserName = ref("Unknown user");
    const unknownUserName = computed(() => t("calls.callWindow.unknownUser"));

    const callStatus = computed(() => {
        if (currentCallRole.value === callRoles.caller && !hasActiveCallSession.value) {
            return subscriberIsBusy.value ? t("calls.callWindow.callStatuses.busy") : t("calls.callWindow.callStatuses.connecting");
        }
        if (currentCallRole.value === callRoles.callee && !hasActiveCallSession.value) {
            return t("calls.callWindow.callStatuses.awaiting");
        }
        if (hasActiveCallSession.value) {
            return t("calls.callWindow.callStatuses.connected");
        }
        return "";
    });

    const setRemoteUserName = async (phoneNumber: string) => {
        remoteUserName.value = unknownUserName.value;

        try {
            const user = await usersService.findUser({ sipNumber: phoneNumber });
            remoteIsInternal.value = !!user;

            if (user) {
                remoteUserName.value = user.fullName;
            } else {
                const contact = await referencesService.getContactNameByPhoneNumber(phoneNumber);
                if (contact?.name) {
                    remoteUserName.value = contact.name;
                }
            }
        } catch (error) {
            console.error("Error getting remote user name:", error);
        }
    };

    const makeCall = async (ua: JsSIP.UA, sipWSHost: string, options: any, setUserCallStatus: (status: UserCallStatus) => void) => {
        if (!ua || !phoneNumber.value) {
            console.error("UA is not initialized or phone number is missing");
            return null;
        }

        await setRemoteUserName(phoneNumber.value);
        const newSession = ua.call(`sip:${phoneNumber.value}@${sipWSHost}`, options);
        setUserCallStatus(UserCallStatus.BUSY);

        newSession.on("progress", async (e: any) => {
            store.commit("calls/setSourceCallId", e.response.call_id);
            if (e.originator === "remote" && +e.response.status_code >= 180 && +e.response.status_code <= 190) {
                store.commit("calls/setActiveCallSession", true);
                return true;
            }
            return false;
        });

        return newSession;
    };

    const answerCall = async () => {
        const session = getSessionRef();

        if (!session) {
            console.log("No call session to answer");
            return false;
        }

        try {
            const extendedSession = session as RTCSession & { _request?: any };
            store.commit("calls/setDestinationCallId", extendedSession._request.call_id);
            session.answer(sipOptions);
            return true;
        } catch (error) {
            console.error("Error answering call:", error);
            return false;
        }
    };

    const hangupCall = async () => {
        const session = getSessionRef();

        if (!session) {
            console.log("No call session to hang up");
            return false;
        }

        try {
            session.terminate();
            return true;
        } catch (error) {
            console.error("Error terminating session:", error);
            return false;
        }
    };

    const toggleHoldCall = async (sipNumber: string | null) => {
        if (!sipNumber) {
            console.error("SIP number is missing");
            return false;
        }

        try {
            await callsService.toggleHold({
                exten: String(sipNumber),
                isOnHold: isCallOnHold.value,
            });
            return true;
        } catch (error) {
            const errorMessage = (error as any)?.response?.data?.message || String(error);
            toast.error(errorMessage);
            return false;
        }
    };

    const transferCall = async (sipNumber: string | null, transferToNumber: string, sourceNumber: string | null) => {
        if (!sipNumber || !sourceNumber) {
            console.error("SIP number or source number is missing");
            return false;
        }

        try {
            const destination = transferToNumber;

            await callsService.redirect({
                exten: String(sourceNumber),
                initiator: String(sipNumber),
                destination: String(destination),
            });

            const session = getSessionRef();
            if (session) {
                session.terminate();
            }

            return true;
        } catch (error) {
            console.error("Error transferring call:", error);
            return false;
        }
    };

    const sendDTMF = (tone: string) => {
        const session = getSessionRef();

        if (!session) {
            console.log("No call session; cannot send DTMF");
            return false;
        }

        try {
            session.sendDTMF(tone);
            return true;
        } catch (error) {
            console.error("Error sending DTMF:", error);
            return false;
        }
    };

    const handleAudioStream = (onStreamReceived: (stream: MediaStream) => void) => {
        const session = getSessionRef();
        if (!session) return;

        session.connection.addEventListener("track", async (event: RTCTrackEvent) => {
            const remoteStream = event.streams[0];
            if (remoteStream) {
                onStreamReceived(remoteStream);
            }
        });
    };

    // Setup DTMF handling
    watch(
        () => [isDtmfMode.value, dtmfTone.value],
        ([mode, tone]) => {
            if (mode && tone) {
                sendDTMF(tone as string);
            }
        }
    );

    // Setup mute handling
    watch(
        () => isCallMuted.value,
        (mute) => {
            const session = getSessionRef();
            if (!session) {
                console.warn("No active call session; cannot mute/unmute");
                return;
            }

            if (mute) {
                session.mute({ audio: true });
                console.log("Call is now muted");
            } else {
                session.unmute({ audio: true });
                console.log("Call is now unmuted");
            }
        }
    );

    return {
        callRoles,
        currentCallRole,
        hasActiveCallSession,
        isDialing,
        phoneNumber,
        remotePhoneNumber,
        transferToNumber,
        isCallOnHold,
        isCallMuted,
        remoteUserName,
        remoteIsInternal,
        subscriberIsBusy,
        callStatus,
        prevNumber,
        makeCall,
        answerCall,
        hangupCall,
        toggleHoldCall,
        transferCall,
        sendDTMF,
        handleAudioStream,
        setRemoteUserName,
    };
}
