import { SourceType } from "@/services/enums";

export type Modal =
    | "login"
    | "register"
    | "registerConfirmEmail"
    | "signUpByInvitationModal"
    | "newDeal"
    | "newLead"
    | "newPriceList"
    | "newClient"
    | "newTask"
    | "filterDeals"
    | "filterLeads"
    | "filterPriceLists"
    | "filterCalls"
    | "filterClients"
    | "filterTasks"
    | "leadsMultiAction"
    | "clientsMultiAction"
    | "tasksMultiAction"
    | "dealsMultiAction"
    | "priceListMultiAction"
    | "callsMultiAction"
    | "successChange"
    | "successCopiedLink"
    | "confirmDelete"
    | "changesNotSaved"
    | "makeCallError"
    | "generate2FA"
    | "authenticate2FA"
    | "filterUsers"
    | "newUser"
    | "resetPassword"
    | "resetPasswordCode"
    | "resetPasswordPassword"
    | "dialPad"
    | "call"
    | "transferCallTo"
    | "fileViewer"
    | "newTtn"
    | "printDelivery"
    | "errorAddTtn"
    | "errorPrintDelivery"
    | "additionalContact"
    | "confirmGoogleAuthenticator"
    | "addDealPriceList"
    | "filterDelivery"
    | "exportLeads"
    | "importLeads"
    | "exportDeals"
    | "importDeals"
    | "exportPriceLists"
    | "importPriceLists"
    | "exportClients"
    | "importClients"
    | "exportTasks"
    | "importTasks"
    | undefined;

export interface ModalsState {
    currentModal?: Modal;
    sideBarOpened: boolean;
    params: any;
}

export const sourceToModalName = {
    [SourceType.LEAD]: "leadsMultiAction",
    [SourceType.CLIENT]: "clientsMultiAction",
    [SourceType.TASK]: "tasksMultiAction",
    [SourceType.DEAL]: "dealsMultiAction",
    [SourceType.PRICE_LIST]: "priceListMultiAction",
    [SourceType.CALL]: "callsMultiAction",
};

const ModalsModule = {
    namespaced: true,
    state: {
        currentModal: undefined as Modal,
        sideBarOpened: true,
        params: undefined,
    },
    mutations: {
        setParams: (state: ModalsState, params: any | undefined): void => {
            state.params = params;
        },
        openModal: (state: ModalsState, payload: Modal | undefined): void => {
            state.currentModal = payload;
        },
        closeModal: (state: ModalsState): void => {
            state.currentModal = undefined;
        },
        setSideBarOpened: (state: ModalsState, payload: boolean) => {
            state.sideBarOpened = payload;
        },
    },
};

export default ModalsModule;
