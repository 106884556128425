export enum LogMethod {
    CREATE = "CREATE",
    UPDATE = "UPDATE",
    DELETE = "DELETE",
    ARCHIVE = "ARCHIVE",
    DE_ARCHIVE = "DE_ARCHIVE",
    COMPLETE = "COMPLETE",
    ADD_NOVA_POST_DELIVERY_BY_TTN = "ADD_NOVA_POST_DELIVERY_BY_TTN",
    CREATE_NOVA_POST_DELIVERY = "CREATE_NOVA_POST_DELIVERY",
    REMOVE_DELIVERY = "REMOVE_DELIVERY",
    CREATE_ADDITIONAL_CONTACT = "CREATE_ADDITIONAL_CONTACT",
    UPDATE_ADDITIONAL_CONTACT = "UPDATE_ADDITIONAL_CONTACT",
    DELETE_ADDITIONAL_CONTACT = "DELETE_ADDITIONAL_CONTACT",
    CREATE_NOTE = "CREATE_NOTE",
    UPDATE_NOTE = "UPDATE_NOTE",
    DELETE_NOTE = "DELETE_NOTE",
    CREATE_BARCODE = "CREATE_BARCODE",
    UPDATE_BARCODE = "UPDATE_BARCODE",
    DELETE_BARCODE = "DELETE_BARCODE",
    TASK_ASSIGNED_TO_CUSTOMER = "TASK_ASSIGNED_TO_CUSTOMER",
    TASK_REASSIGNED_TO_CUSTOMER = "TASK_REASSIGNED_TO_CUSTOMER",
    TASK_ASSIGNED_TO_DEAL = "TASK_ASSIGNED_TO_DEAL",
    TASK_REASSIGNED_TO_DEAL = "TASK_REASSIGNED_TO_DEAL",
    DEAL_ASSIGNED_TO_CUSTOMER = "DEAL_ASSIGNED_TO_CUSTOMER",
    DEAL_REASSIGNED_TO_CUSTOMER = "DEAL_REASSIGNED_TO_CUSTOMER",
    DEAL_REWRITE_PRICE_LISTS = "DEAL_REWRITE_PRICE_LISTS",
    CONVERTED_LEAD_IN_CLIENT = "CONVERTED_LEAD_IN_CLIENT",
}
