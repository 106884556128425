import { createI18n } from "vue-i18n";

import localeUkUa from "./uk-ua";
import localeEnUs from "./en-us";
import { ukUaLocaleName, enUsLocaleName, backendEnUsLocaleName, backendUkUaLocaleName, getDefaultLocale } from "@/utils/constants";

export enum Locale {
    UK_UA = "uk-ua",
    EN_US = "en-us",
}

export const messages = {
    [ukUaLocaleName]: localeUkUa,
    [enUsLocaleName]: localeEnUs,
};

export const frontendToBackendLocales = {
    [enUsLocaleName]: backendEnUsLocaleName,
    [ukUaLocaleName]: backendUkUaLocaleName,
} as const;

export const backendToFrontendLocales = {
    [backendEnUsLocaleName]: enUsLocaleName,
    [backendUkUaLocaleName]: ukUaLocaleName,
} as const;

export default createI18n({
    locale: getDefaultLocale(),
    fallbackLocale: enUsLocaleName,
    legacy: false,
    globalInjection: true,
    messages,
});
