const notifications = {
    title: "Notifications",
    subtitle: "Information about the activity log and emails",
    emptyList: "No new notifications",
    showAll: "Show all",
    tabTitles: {
        messages: "Mail",
        activityLog: "Activity log",
    },
    convertedInClient: "Converted lead Id {leadId} into client Id {clientId}",
};

export default notifications;
