const notifications = {
    title: "Сповіщення",
    subtitle: "Інформація про події та електронні листи",
    emptyList: "Немає нових сповіщень",
    showAll: "Показати все",
    tabTitles: {
        messages: "Повідомлення",
        activityLog: "Журнал активності",
    },
    convertedInClient: "Конвертовано ліда Id {leadId} в клієнта Id {clientId}",
};

export default notifications;
