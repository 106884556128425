import Joi from "joi";
import { emailSchema, noEmptyString, phoneNumberSchema } from "../common";
import { enUsLocaleName, ukUaLocaleName } from "@/utils/constants";

const initialsErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Поле не може бути порожнім.",
        "string.min": "Поле повинно містити не менше 2 символів.",
        "string.max": "Поле не може містити більше 100 символів.",
        "any.required": "Поле є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.empty": "The field cannot be empty.",
        "string.min": "The field must be at least 2 characters long.",
        "string.max": "The field cannot exceed 100 characters.",
        "any.required": "The field is required.",
    },
};

const positionErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Поле не може бути порожнім.",
        "string.min": "Поле повинно містити хоча б 1 символ.",
        "string.max": "Поле не може містити більше 100 символів.",
    },
    [enUsLocaleName]: {
        "string.empty": "The field cannot be empty.",
        "string.min": "The field must contain at least 1 character.",
        "string.max": "The field cannot exceed 100 characters.",
    },
};

export const additionalContactRequiredFields: string[] = ["firstName", "lastName", "phoneNumber"];

export const createAdditionalContactSchema = Joi.object({
    firstName: noEmptyString.min(2).max(100).required().messages(initialsErrMsgs),
    lastName: noEmptyString.min(2).max(100).required().messages(initialsErrMsgs),
    middleName: noEmptyString.min(3).max(100).allow("").messages(initialsErrMsgs),
    position: noEmptyString.max(100).allow("").messages(positionErrMsgs),
    phoneNumber: phoneNumberSchema.required(),
    email: emailSchema.allow(""),
    novaPostDeliveryAddress: Joi.object().allow(null),
    ukrPostDeliveryAddress: Joi.object().allow(null),
});
