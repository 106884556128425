import Joi from "joi";
import { AccessLevels, enUsLocaleName, ROLE_NAME_LENGTH, ukUaLocaleName } from "../../constants";
import { groupPermissionByResource, splitPermissionToParts } from "@/utils/permissions";
import { Permission } from "@/services/types";
import { noEmptyString } from "../common";

const errMsgs = {
    [ukUaLocaleName]: {
        "string.max": `Назва ролі має бути менше чи дорівнює ${ROLE_NAME_LENGTH} символам.`,
        "string.empty": "Назва ролі не може бути порожньою.",
        "any.required": "Назва ролі є обов'язковим полем.",
    },
    [enUsLocaleName]: {
        "string.max": `Role name must be less than or equal to ${ROLE_NAME_LENGTH} characters.`,
        "string.empty": "Role name cannot be empty.",
        "any.required": "Role name is a required field.",
    },
};

const permissionsErrMsgs = {
    [ukUaLocaleName]: {
        "any.custom": 'Доступ "Може переглядати..." має бути обрано',
    },
    [enUsLocaleName]: {
        "any.custom": 'The permission "Can view..." must be selected.',
    },
};

export const testSchema = Joi.object({
    name: noEmptyString.max(ROLE_NAME_LENGTH).required().messages(errMsgs),
    permissions: Joi.any()
        .custom((value, helper) => {
            const hasEveryResourceCanViewPermission = Object.values(groupPermissionByResource(value))
                .map((permissions: Permission[]) => permissions.map((perm) => splitPermissionToParts(perm).accessLevel))
                .every((accessLevels: string[]) => accessLevels.includes(AccessLevels.VIEW));

            if (!hasEveryResourceCanViewPermission) {
                return helper.error("any.custom");
            }

            return value;
        }, "custom-err")
        .messages(permissionsErrMsgs),
});

export const roleNameSchema = noEmptyString.max(ROLE_NAME_LENGTH).required().messages(errMsgs);
