export type DownloadTypeOptions = "downloadAll" | "downloadFiltered";
export const DownloadType: { [key in DownloadTypeOptions]: DownloadTypeOptions } = {
    downloadAll: "downloadAll",
    downloadFiltered: "downloadFiltered",
};

export type DownloadFileTypeOptions = "CSV" | "XLSX";

export const DownloadFileType: { [key in DownloadFileTypeOptions]: DownloadFileTypeOptions } = {
    CSV: "CSV",
    XLSX: "XLSX",
};

export type UniqueFieldOptions = "noChecks" | string;
export const UniqueField = {
    noChecks: "noChecks",
} as const;

export type ImportData = { fileType: DownloadFileTypeOptions; file: File; uniqueFieldId: string };
