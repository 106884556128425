export interface MenuContext {
    hasEmailAccounts: boolean;
}
export interface MenuItem {
    label: string;
    path: string;
    redirectPath?: string;
    icon: string;
    items?: MenuItem[];
    extended?: boolean;
    isVisible?: (state: MenuContext) => boolean;
}

export const mainMenuTree: MenuItem[] = [
    { label: "sidebar.items.main", path: "/", icon: "dashboard" },
    { label: "sidebar.items.leads", path: "/leads", icon: "comment-user" },
    { label: "sidebar.items.clients", path: "/clients", icon: "user-add" },
    { label: "sidebar.items.deals", path: "/deals", icon: "assept-document" },
    { label: "sidebar.items.tasks", path: "/tasks", icon: "list-check" },
    { label: "sidebar.items.calls", path: "/calls", icon: "custom-calls" },
    { label: "sidebar.items.priceList", path: "/price-list", icon: "money" },
    { label: "sidebar.items.statistic", path: "/statistic", icon: "chart-histogram" },
    { label: "sidebar.items.delivery", path: "/delivery", icon: "truck-side" },
    {
        label: "sidebar.items.mail.title",
        path: "/mail",
        icon: "envelope",
        items: [
            { label: "sidebar.items.mail.items.inbox", path: "/mail/inbox", icon: "envelope-download", isVisible: (state: MenuContext) => state.hasEmailAccounts },
            { label: "sidebar.items.mail.items.sent", path: "/mail/sent", icon: "custom-mail", isVisible: (state: MenuContext) => state.hasEmailAccounts },
            { label: "sidebar.items.mail.items.drafts", path: "/mail/drafts", icon: "file", isVisible: (state: MenuContext) => state.hasEmailAccounts },
            { label: "sidebar.items.mail.items.starred", path: "/mail/starred", icon: "star", isVisible: (state: MenuContext) => state.hasEmailAccounts },
            { label: "sidebar.items.mail.items.archive", path: "/mail/archive", icon: "archive", isVisible: (state: MenuContext) => state.hasEmailAccounts },
            { label: "sidebar.items.mail.items.spam", path: "/mail/spam", icon: "exclamation", isVisible: (state: MenuContext) => state.hasEmailAccounts },
            { label: "sidebar.items.mail.items.trash", path: "/mail/trash", icon: "trash", isVisible: (state: MenuContext) => state.hasEmailAccounts },
        ],
    },
    {
        label: "sidebar.items.settings.title",
        path: "/settings",
        icon: "settings",
        items: [
            {
                label: "sidebar.items.settings.items.user",
                path: "/settings/users",
                icon: "user",
            },
            {
                label: "sidebar.items.settings.items.roles",
                path: "/settings/roles",
                icon: "head-side-thinking",
            },
            {
                label: "sidebar.items.settings.items.leads",
                path: "/settings/lead",
                icon: "comment-user",
            },
            {
                label: "sidebar.items.settings.items.clients",
                path: "/settings/client",
                icon: "user-add",
            },
            {
                label: "sidebar.items.settings.items.deals",
                path: "/settings/deal",
                icon: "assept-document",
            },
            {
                label: "sidebar.items.settings.items.task",
                path: "/settings/task",
                icon: "list-check",
            },
            {
                label: "sidebar.items.settings.items.priceList",
                path: "/settings/price-list",
                icon: "money",
            },
            {
                label: "sidebar.items.settings.items.notifications",
                path: "/settings/notifications",
                icon: "fi-rr-bell-ring",
            },
            {
                label: "sidebar.items.settings.items.integrations",
                path: "/settings/integrations",
                icon: "chart-connected",
            },
            {
                label: "sidebar.items.mail.title",
                path: "/settings/mail",
                icon: "envelope",
            },
            {
                label: "sidebar.items.settings.items.personal",
                path: "/settings/personal",
                icon: "head-side-thinking",
            },
        ],
    },
];
