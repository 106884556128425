const errors = {
    internalServerError: "Внутрішня помилка серверу",
    retrievingDataError: "Помилка при отриманні даних",
    smthWentWrong: "Щось пішло не так. Спробуйте, будь ласка, ще раз.",
    notFoundTitle: "Ой, сторінку не знайдено!",
    notFoundSubtext: "Здається, такої сторінки не існує або вона була переміщена.",
    backToMain: "Повернутися на головну",
};

export default errors;
