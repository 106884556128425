const settings = {
    main: {
        title: "Settings",
        subtitle: "Track the essentials on the Dashboard",
        customFields: {
            title: "Entity fields settings",
            subtitle: "You can add additional fields for necessary entities",
            priceList: "Price list",
            lead: "Lead",
            client: "Client",
            deal: "Deal",
            task: "Task",
            users: "Users",
            roles: "Roles",
            notifications: "Notifications",
            integrations: "Integrations",
            mail: "Mails",
            personal: "Personal",
        },
    },
    users: {
        userInfo: {
            title: "User personal card",
        },
        positions: {
            modal: {
                titleCreate: "Create position",
                titleUpdate: "Edit position",
                saveBtn: "Save",
                inputLabel: "Position",
                inputPlaceholder: "Type the name of position",
                errors: {
                    required: `The field "position" is required`,
                },
                deleteConfirmationTitle: `After deletion, the position "Manager" will be assigned to {userAmount} users`,
                deleteConfirmationSubtitle: `Do you confirm the deletion?`,
            },
        },

        positionsTable: {
            title: "Positions",
            emptyMessage: "There is no data",
            emptySearchResult: "No results found",
            panel: {
                createPosition: "Add new position",
            },
            columns: {
                name: "Name",
                actions: "Actions",
            },
        },

        table: {
            title: "Users",
            emptyMessage: "There is no data",
            emptySearchResult: "No results found",
            panel: {
                createUser: "Add new user",
            },
            columns: {
                fullName: "Full name",
                login: "Email",
                role: "Role",
                position: "Position",
                status: "Status",
                actions: "Actions",
            },
        },
        newUserModal: {
            header: "Create user",
            inputs: {
                fullName: "Full name *",
                login: "Email *",
                photo: "Photo",
                phoneNumber: "Your phone number",
                phoneCountry: "Phone country",
                password: "Password *",
                passwordError: "At least 3 symbols, maximum 15",
                confirmPassword: "Confirm password *",
                role: "Role *",
                position: "Position *",
            },
            placeholders: {
                fullName: "Type full name of the user",
                login: "Type email",
                photo: "Upload file",
                password: "Create the password",
                confirmPassword: "Repeat the password",
            },
        },
        deleteUserModal: {
            title: "Do you confirm the deletion of the user {userName}?",
            changeResponsibleSubtitle: "You can change the responsible person through bulk actions by going to the relevant parts of the application.",
            changeResponsible: "Change responsible",
        },
        filter: {
            header: "Filter",
            inputs: {
                name: "Full name",
                email: "Email",
                role: "Role",
                position: "Position",
            },
            placeholders: {
                name: "Type full name of the user",
                email: "Type email",
            },
        },
    },
    modals: {
        error: "An error occurred while processing your request",
    },
    roles: {
        title: "Settings",
        subtitle: "Track the essentials on the Dashboard",
        add: "Create role",
        table: {
            title: "Roles",
            columns: {
                name: "Role",
                permissions: "Permissions",
                actions: "",
            },
        },
        modal: {
            create: {
                header: "Create role",
                inputs: { name: "Name" },
                submit: "Create",
            },
            update: {
                header: "Edit role",
                inputs: { name: "Role name" },
                submit: "Save",
            },
            view: {
                header: "View role",
                inputs: { name: "Role name" },
            },

            delete: {
                deleteDeliveryConfirmationSubtitle: "Do you confirm the deletion of delivery?",
                deletedDeliveryMessage: "Delivery has been deleted",
                deleteConfirmationTitle: `After deletion, the role "User" will be assigned to {usersCount} user(s)`,
                deleteConfirmationSubtitle: `Do you confirm the deletion?`,
            },

            header: "",
        },
        permissions: {
            selectAll: "Select All",
            cancelAll: "Unselect All",
            leads: "Leads",
            clients: "Clients",
            deals: "Deals",
            tasks: "Tasks",
            "price-list": "Price list",
            calls: "Calls",
            settings: "Settings",
            roles: "Roles",
            users: "Users",
            deliveries: "Delivery",
            statistics: "Statistics",
            "users-settings": "Users settings",
            "roles-settings": "Roles settings",
            "leads-settings": "Leads settings",
            "clients-settings": "Clients settings",
            "deals-settings": "Deals settings",
            "tasks-settings": "Tasks settings",
            "price-lists-settings": "Price list settings",
        },

        levels: {
            view: "Can view",
            create: "Can create",
            update: "Can edit",
            delete: "Can delete",
            "request-archive": "Can request archive",
            "archive-and-dearchive": "Archive and dearchive",
            import: "Can import",
            export: "Can export",
        },
    },
    common: {
        multipleValues: {
            close: "Close",
            confirmValueRemoving: "Really delete?",
            valuePlaceholder: "Enter text",
            valueName: "Enter options",
            header: "Change field",
            duplicatedOptions: 'The option "{option}" already existed.',
        },
        add: "Add",
        status: "Status",
        customerType: "Type",
        source: "Source",
        currency: "Currency (price lists and deals)",
        dealStage: "Stage",
        errors: {
            duplicatedWithPredefinedFields: 'The field name "{name}" is already used for system fields, please choose a different name',
        },
    },
    customFields: {
        modal: {
            create: {
                header: "Create new field",
                inputs: {
                    name: "Field name",
                    type: "Field type",
                    value: "Enter options",
                    valuePlaceholder: "Enter new value and press Enter",
                },
                submit: "Create",
            },
            update: {
                header: "Edit field",
                inputs: {
                    name: "Field name",
                    type: "Field type",
                    value: "Enter options",
                    valuePlaceholder: "Enter new value and press Enter",
                },
                submit: "Save",
                warningDataMayBeLost: "Entered data in this field may be lost",
            },
            delete: {
                deleteConfirmationTitle: `Field will be deleted from all entities`,
                deleteConfirmationSubtitle: `Do you confirm the deletion?`,
            },
        },
        type: {
            INPUT: "Input",
            DROPDOWN: "Dropdown",
        },
        table: {
            columns: {
                name: "Name",
                type: "Type",
                actions: "",
            },
        },
        fieldsOrderChanged: "Order of fields has been changed",
    },
    LEAD: {
        title: "Settings",
        subtitle: "Track the essentials on the Dashboard",
        add: "New field",
        table: {
            title: "Lead",
        },
    },
    CLIENT: {
        title: "Settings",
        subtitle: "Track the essentials on the Dashboard",
        add: "New field",
        table: {
            title: "Client",
        },
    },
    funnels: {
        title: "Funnels",
        addStage: "Add stage",
        addNew: "Add new",
        saveBeforeAddingNew: "Save new funnel before adding one more",
        stageOrderChanged: "Order of stages has been changed",
    },
    status: {
        title: "Status management",
        subtitle: "Status",
        add: "Add status",
        statusOrderChanged: "Order of statuses has been changed",
    },
    DEAL: {
        title: "Settings",
        subtitle: "Deals",
        add: "New Field",
        table: {
            title: "Deal",
        },
    },
    TASK: {
        title: "Settings",
        subtitle: "Track the essentials on the Dashboard",
        add: "New Field",
        table: {
            title: "Task",
        },
    },
    PRICE_LIST: {
        title: "Settings",
        subtitle: "Track the essentials on the Dashboard",
        add: "New Field",
        table: {
            title: "Price list",
        },
        barcodes: {
            instructionDescr: `Settings for generating PDF with barcodes. The barcodes themselves are added to the internal 
                page of the product in the corresponding block. They can also be imported.`,
            instruction: "Instruction",
            commonSettings: "Common Settings",
            addArticleToPdfFile: "Add article to PDF for barcode",
            addNameToPdfFile: "Add product name to PDF for barcode",
            copyArticleToBarcode: "Copy article to barcode",
            barcodeFormat: "Barcode symbolism during addition",
        },
    },
    mail: {
        title: "Settings",
        subtitle: "Mail",
        accountData: {
            title: "Account data",
            senderNameTitle: "Sender's name",
            senderNamePlaceholder: "Enter configured sender name",
            emailTitle: "Email",
            connected: "Connected",
        },
        mailSync: {
            title: "Email data synchronization",
            syncStarted: "Synchronization started...",
            syncing: "Synchronizing emails...",
            syncCompleted: "Synchronization completed",
            syncFailed: "Synchronization failed",
            syncTimeout: "Synchronization timed out",
            lastSynced: "Last synchronized",
            neverSynced: "Never synchronized",
            accountNotFound: "No email accounts found",
            syncCheckFailed: "Failed to check sync status",
            syncStatusNotAvailable: "Sync status information not available",
            syncFromTitle: "Synchronize from",
            syncButton: "Synchronize",
            month: "1 month",
            threeMonths: "3 months",
            sixMonths: "6 months",
            description: `All your actions with archiving emails, starred emails and drafts are only displayed within
the CRM system and are not synchronized with your email provider. 
Please consider this information when using email integration.`,
        },
        accountManage: {
            title: "Account management",
            stopSyncButton: "Stop synchronization",
            removeAccountButton: "Delete account",
        },
    },
    novaposhta: {
        title: "Settings",
        subtitle: "Integrations",
        infoTtnDescription:
            "Form TTH according to the agreement quickly and conveniently. " +
            "Automatic substitution of the data of the recipient, the sender, information about the product." +
            " Automatic transfer of transactions by stages depending on the delivery status, a list for the courier, multi-accounting.",
        ttnInstructionTitle: "Primary settings",
        prioritySettingsTitle: "Priority settings",
        toggleOnIntegration: "Enable integration",
        relatedStatusesCardTitle: "Related statuses",
        noReturnShipping: "No return shipping",
        noAutoGenerationAdditionalInfo: "No auto-generation of additional information",
        placeProductNameToAddtionalInfo: "Place the product name in the additional information",
        placeArticleAndQuantityToAdditionalInfo: "Place the article and quantity in additional information",
        privateIndividual: "Private individual",
        inTheDepartment: "In the department",
        typeOfReceivingMoneyTitle: "Type of receiving money",
        relatedStatusesAndPaymentCardTitle: "Related payment types and statuses",
        relatedStatusesCardSubTitle:
            // eslint-disable-next-line max-len
            "Link the statuses of the delivery service and the stages of the KeepinCRM deals so that the orders automatically change their stages depending on the delivery status",
        editPrivateIndividual: {
            title: "Settings",
            subtitle: "Integrations / Individual",
            activateProfile: "Turn on",
            deleteAccount: "Delete account",
            name: "Name",
            apiKey: "Api key",
            sender: "Sender",
            senderFullName: "Sender full name",
            phone: "Phone",
            typeOfCargo: "Type of cargo",
            deliveryMethod: "Delivery method",
            payerOfTheDeliveryCommission: {
                title: "Payer of the delivery commission",
                sender: "Sender",
                recipient: "Recipient",
            },
            deliveryPayer: {
                title: "Delivery payer",
                sender: "Sender",
                recipient: "Recipient",
            },
            city: "City",
            cargoDescription: "Cargo description",
            formOfPayment: "Form of payment",
            sendingDepartment: "Sending department",
            totalWeight: "Total weight(kg)",
            totalShipmentVolume: "Total shipment volume(m3)",
            packageNumber: "Package number",
        },
    },
    integrations: {
        pageSubTitle: "Integrations",
        emails: {
            connected: "Connected",
            reconnect: "Reconnect",
            addEmailProvider: "Add email provider",
            other: "Other",
            selectEmailProvider: "Choose email provider",
            description: `Email integration allows you to work with emails
             directly in CRM. All emails you send or receive are automatically attached to clients, so all communication is easily structured and saved`,
            reconnectDescription: "Your account credentials are outdated. Please click 'Reconnect' to obtain new credentials and continue email synchronization.",
        },
        post: {
            settings: {
                title: "Settings",
                subtitle: "Integrations",
                relatedStatuses: {
                    deliveryStatus: {
                        title: "External status",
                    },
                    dealsStage: {
                        title: "Stage",
                    },
                    warning: {
                        sameStatusId: "Please select different statuses to create related statuses",
                        selectStatusId: "Please select statuses to create related statuses",
                    },
                },
                infoTtnDescription:
                    "Form TTH according to the agreement quickly and conveniently. " +
                    "Automatic substitution of the data of the recipient, the sender, information about the product." +
                    " Automatic transfer of transactions by stages depending on the delivery status, a list for the courier, multi-accounting.",
                ttnInstructionTitle: "Primary settings",
                prioritySettingsTitle: "Priority settings",
                toggleOnIntegration: "Enable integration",
                toggleOffIntegration: "Disable integration",
                relatedStatusesCardTitle: "Related statuses",
                noReturnShipping: "No return shipping",
                noAutoGenerationAdditionalInfo: "No auto-generation of additional information",
                placeProductNameToAdditionalInfo: "Place the product name in the additional information",
                popupPlaceProductNameToAdditionalInfo:
                    "The system will automatically add the names of goods to the “Additional information” field when generating the consignment note.",
                placeArticleAndQuantityToAdditionalInfo: "Place the article and quantity in additional information",
                popupPlaceArticleAndQuantityToAdditionalInfo:
                    "The system will automatically add the product article, quantity, " +
                    "and units of measurement to the “Additional information” field when generating the waybill." +
                    " Example: sku12-3pcs.",
                privateIndividual: "Private individual",
                inTheDepartment: "In the department",
                typeOfReceivingMoneyTitle: "Type of receiving money",
                relatedStatusesAndPaymentCardTitle: "Related payment types and statuses",
                relatedStatusesCardSubTitle:
                    "Link the statuses of the delivery service and the stages of the KeepinCRM deals so that the orders " +
                    "automatically change their stages depending on the delivery status",
                editPrivateIndividual: {
                    title: "Settings",
                    subtitle: "Integrations / Individual",
                    active: "Turned on",
                    inActive: "Turned off",
                    deleteAccount: "Delete account",
                    name: "Name",
                    apiKey: "Api key",
                    apiToken: "Bearer token",
                    senderType: "Sender type",
                    senderFirstName: "Sender first name",
                    senderMiddleName: "Sender middle name",
                    senderLastName: "Sender last name",
                    companyName: "Company name",
                    uniqueRegistrationNumber: "Unique registration number",
                    phone: "Phone",
                    typeOfCargo: "Type of cargo",
                    deliveryMethod: "Delivery method",
                    clientTypes: {
                        INDIVIDUAL: "Individual",
                        CORPORATE: "Company",
                        THIRD_PERSON: "Third person",
                    },
                    cargoTypes: {
                        PARCEL: "Parcel",
                        DOCUMENTS: "Documents",
                    },
                    deliveryTypes: {
                        W2W: "Warehouse-to-warehouse",
                        W2D: "Warehouse-to-door",
                        D2W: "Door-to-warehouse",
                        D2D: "Door-to-door",
                    },
                    paymentTypes: {
                        CASH: "Cash",
                        NON_CASH: "Cashless",
                    },
                    payerOfTheDeliveryCommission: {
                        title: "Payer of the delivery commission",
                        sender: "Sender",
                        recipient: "Recipient",
                    },
                    deliveryPayer: {
                        title: "Delivery payer",
                        sender: "Sender",
                        recipient: "Recipient",
                    },
                    city: "City",
                    cargoDescription: "Cargo description",
                    formOfPayment: "Form of payment",
                    departmentType: "Department type",
                    sendingDepartment: "Sending department",
                    totalWeight: "Total weight(kg)",
                    totalShipmentVolume: "Total shipment volume(m3)",
                    packageNumber: "Package number",
                    additionalInfo: {
                        title: "Additional info",
                        empty: "Do not fill the internal shipment number",
                        fill: "Fill the internal shipment number",
                        popupFill:
                            "When creating a consignment note, the filling of each place (Length x Width x Height), weight (kg)," +
                            " and the ability to specify “Shipment not in a box” are automatically enabled. \n" +
                            "\n" +
                            "This function also allows you to automatically substitute this data from the goods (if they are filled in the “Number of places” tab), " +
                            "or from the volumetric weight calculator in the agreement. \n" +
                            "\n" +
                            "If the product takes up several places, " +
                            "this is a convenient function for filling in the data for each product once and auto-filling when creating a consignment note.",
                        dealId: "Place the deal ID on the internal shipment number",
                        dealName: "Place the deal name on the internal shipment number",
                        priceList: "Place product field information on the internal shipment number",
                    },
                    accompanyingDocuments: {
                        title: "Accompanying documents",
                        fromDealPriceListAmount: "Number of places based on the quantity of goods in the deal",
                        fixed: "Fixed number of places",
                        fromPriceListAmount: "Number of places from the custom field in the product",
                        popupFromPriceListAmount:
                            "This setting is required if the goods are large and take up more than 1 place during shipment. " +
                            "To speed up the creation of the waybill, specify the field from which the system will take information about the number of places." +
                            " If there are 2 goods in the transaction: in 1 product the field is filled in (2 places are indicated), " +
                            "in the other it is not filled in, then the system will automatically fill in the number of cities = 3.\n" +
                            "\n" +
                            "Translated with DeepL.com (free version)",
                    },
                    deliverySize: {
                        title: "Delivery size",
                        subtitle:
                            "If only the general parameters such as weight and number of places are filled in when creating a consignment note," +
                            " select 'General parameters', if it is necessary to fill in the dimensions (Length x Width x Height) for each place," +
                            " select 'Fill each place' while this can be changed in the consignment note creation window." +
                            " This feature allows for automatic filling of the most commonly used method.",
                        general: "General parameters",
                        popupGeneral:
                            "When creating a consignment note, filling in the total weight (kg), total volume (m3), and number of places is automatically enabled.",
                        fillEach: "Fill each place",
                        enableSelect: "Enable selection of shipping options",
                        fillPaymentCheck: "Fill payment check if without return shipping",
                        popupFillPaymentCheck:
                            "The Payment Control service ensures the transfer of funds to the seller's current account, " +
                            "which the recipient of the goods uses to pay for cash on delivery",
                    },
                },
            },
            ["nova-post"]: {
                connected: "Connected",
                addPoshtaProvider: "Add account Nova Poshta",
                addProviderTitle: "Nova Poshta",
                add: "Add",
                inputApiKeyLabel: "Input API key",
                inputApiKeyPlaceholder: "Api key",
                remove: "Cancel",
                other: "Other",
                descriptor: `Our dear customers!
                On the occasion of our first year together, we are happy to share with you the great news - the freshest trends of the season have appeared in our store! 
                New arrivals of famous brands are guaranteed to add unique charm and style to your wardrobe.`,
                invalidApiKeyErr: "API key incorrect",
            },
            ["ukr-post"]: {
                connected: "Connected",
                addUkrPoshtaProvider: "Add account Ukr Poshta",
                addProviderTitle: "UkrPoshta",
                add: "Add",
                inputApiKeyLabel: "Input API key",
                inputApiKeyPlaceholder: "Api key",
                inputTokenLabel: "Input Bearer token",
                inputTokenPlaceholder: "Bearer token",
                remove: "Cancel",
                descriptor: `Our dear customers!
                On the occasion of our first year together, we are happy to share with you the great news - the freshest trends of the season have appeared in our store! 
                New arrivals of famous brands are guaranteed to add unique charm and style to your wardrobe.`,
            },
        },
    },
    personal: {
        title: "Two-factor authentication",
        subTitle: "One of this methods is required",
        pageSubtitle: "Personals",
        addButton: "Add",
        modal: {
            header: "Connect Authenticator app",
            description: "To enable two-factor authentication, you need to connect the Authenticator app.",
            step1: "1. Download the Authenticator app.",
            step2: "2. Use the app to scan the QR code.",
            step3: "3. Enter the code generated by the app.",
            inputs: {
                code: "Verification code",
                appName: "Verifier name",
            },
            placeholders: {
                code: "Enter verification code",
                appName: "Enter verifier name",
            },
            cancel: "Cancel",
            submit: "Connect",
        },
        errors: {
            lastIssuer: "You can't delete the last issuer before deactivating the authenticator 2FA method",
            enableEmailVerification: "Please enable Email verification first.",
            enableAuthenticator: "Please enable Google authenticator first.",
        },
    },
    notifications: {
        pageSubTitle: "Notifications",
        selectAll: "Select all",
        channels: {
            title: "Common",
            notifyByEmail: "Receive Email Notifications",
            notifyBySocket: "Allow Push Notifications",
            notifyWithSound: "Sound Notifications",
        },
        leads: {
            title: "Leads",
            description: "Receive notifications about leads",
            create: "Create ",
            update: "Edit lead",
            convertInClient: "Converted to client",
            addTask: "Add task",
            archive: "Archiving",
            deArchive: "Dearchiving",
        },
        clients: {
            title: "Clients",
            description: "Receive notifications about clients",
            create: "Create client",
            update: "Edit client",
            addTask: "Add task",
            addDeal: "Add deal",
            archive: "Archiving client",
            deArchive: "Dearchiving",
        },
        deals: {
            title: "Deals",
            description: "Receive notifications about deals",
            create: "Create deal",
            update: "Edit deal",
            createDelivery: "Create delivery",
            addTask: "Add task",
            complete: "Complete deal",
            archive: "Archiving deal",
            deArchive: "Dearchiving deal",
        },
        tasks: {
            title: "Tasks",
            description: "Receive notifications about tasks",
            create: "Create task",
            update: "Edit task",
            addTask: "Add task",
            complete: "Complete task",
            archive: "Archiving task",
            deArchive: "Dearchiving task",
        },
        priceLists: {
            title: "Price Lists",
            description: "Receive notifications about price lists",
            create: "Create product/service",
            update: "Edit product/service",
            archive: "Archiving product/service",
            deArchive: "Dearchiving product/service",
        },
        emails: {
            title: "Email",
            description: "Receive notifications about new emails",
            notifyNewEmails: "Receive a notifications from the email",
        },
    },
};

export default settings;
