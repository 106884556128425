import Joi from "joi";
import { enUsLocaleName, ukUaLocaleName } from "@/utils/constants";
import { RegexPresets } from "@/uikit/utils/validators/regex";
import { emailSchema, noEmptyString, phoneNumberSchema } from "../../common";

const fullNameErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Поле ПІБ не може бути порожнім.",
        "string.max": "Поле ПІБ не може містити більше 100 символів.",
        "string.min": "Поле ПІБ не може містити менше 3 символів.",
        "any.required": "Поле ПІБ не може бути порожнє",
    },
    [enUsLocaleName]: {
        "string.empty": "The full name field cannot be empty.",
        "string.max": "The full name cannot be longer than 100 characters.",
        "string.min": "The full name cannot be shorter than 3 characters.",
        "any.required": "The full name field cannot be empty.",
    },
};

const passwordErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Пароль має бути мінімум 3 символи",
        "string.max": "Пароль не може містити більше 100 символів.",
        "string.min": "Пароль має бути мінімум 3 символи",
        "string.pattern.base": "Пароль має містити мінімум 8 символів, 1 велику і 1 малу літери",
    },
    [enUsLocaleName]: {
        "string.empty": "The password must be at least 3 characters long.",
        "string.max": "The password cannot exceed 100 characters.",
        "string.min": "The password must be at least 3 characters long.",
        "string.pattern.base": "The password must contain at least 8 characters, including 1 uppercase and 1 lowercase letter.",
    },
};

const confirmPasswordErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Пароль має бути мінімум 3 символи",
        "string.max": "Пароль не може містити більше 100 символів.",
        "string.min": "Пароль має бути мінімум 3 символи",
        "any.required": 'Значення цього поля має бути однакове із полем "пароль"',
        "any.only": 'Значення цього поля має бути однакове із полем "пароль"',
    },
    [enUsLocaleName]: {
        "string.empty": "The password must be at least 3 characters long.",
        "string.max": "The password cannot exceed 100 characters.",
        "string.min": "The password must be at least 3 characters long.",
        "any.required": 'This field must match the "password" field.',
        "any.only": 'This field must match the "password" field.',
    },
};

const roleErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "роль" є обов\'язковим.',
        "number.positive": 'Поле "роль" є обов\'язковим.',
        "any.required": 'Поле "роль" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "role" field is required.',
        "number.positive": 'The "role" field is required.',
        "any.required": 'The "role" field is required.',
    },
};

const positionErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "посада" є обов\'язковим.',
        "number.positive": 'Поле "посада" є обов\'язковим.',
        "any.required": 'Поле "посада" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "position" field is required.',
        "number.positive": 'The "position" field is required.',
        "any.required": 'The "position" field is required.',
    },
};

export * from "./position";
export const createUserSchema = Joi.object({
    fullName: noEmptyString.min(3).max(100).required().messages(fullNameErrMsgs),
    login: emailSchema.required(),
    roleId: Joi.number().integer().positive().required().messages(roleErrMsgs),
    positionId: Joi.number().integer().positive().required().messages(positionErrMsgs),
});

export const updateUserSchema = Joi.object({
    fullName: noEmptyString.min(3).max(100).required().messages(fullNameErrMsgs),
    login: emailSchema.allow(""),
    phoneNumber: phoneNumberSchema.allow(""),
    roleId: Joi.number().integer().positive().required().messages(roleErrMsgs),
    positionId: Joi.number().integer().positive().required().messages(positionErrMsgs),
    password: Joi.string().allow("").pattern(RegexPresets.newPassword).messages(passwordErrMsgs),
    confirmPassword: Joi.when("password", {
        is: Joi.string().min(3).max(15).required(),
        then: Joi.valid(Joi.ref("password")).required(),
        otherwise: Joi.allow(""),
    }).messages(confirmPasswordErrMsgs),
});
