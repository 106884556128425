import { LocalizationStructure } from "@/uikit/utils/localization";

const uikit: LocalizationStructure = {
    components: {
        select: {
            placeholder: "Обрати",
        },
        searchInput: {
            placeholder: "Пошук",
        },
        dateRange: {
            range: "Діапазон",
            cancel: "Скасувати фільтри",
            apply: "Застосувати",
            today: "Сьогодні",
            yesterday: "Вчора",
            thisWeek: "Поточний тиждень",
            thisMonth: "Поточний місяць",
            thisYear: "Поточний рік",
        },
        date: {
            minDateError: "Ця дата менше мінімума. Впишіть коректну дату",
            maxDateError: "Ця дата більше максимума. Впишіть коректну дату",
            incorrectDateError: "Некоректна дата введена. Перевірте значення дня/місяця/року",
            dd: "дд",
            mm: "мм",
            yyyy: "рррр",
            clearDate: "Скинути дату",
        },
        numberRange: {
            from: "Від",
            to: "До",
        },
    },
    locale: "ua",
    fullLocale: "uk-ua",
};

export default uikit;
