const modals = {
    general: {
        error: "There was an error processing your request",
        redirectWarning: "You can click on the message and go to the desired page.",
    },
    editingPhotos: {
        save: "Save",
        cancel: "Cancel",
        title: "Edit photos",
        requirements: "Accept .jpg, .jpeg, .png, .gif, max 10MB, 800x600px to 4000x3000px",
        inputPlaceholder: "Upload a file",
        inputLabel: "Photo",
        selectMainPhoto: "Choose main photo",
    },
    printingRegistry: {
        title: "Adding a document to the register in progress",
        description: "It can take some time",
    },
    uploadingProgress: {
        title: "The files are uploading",
        description: "It can take some time",
        uploading: "The files are uploading",
        uploaded: "Uploaded",
    },
    confirmDelete: {
        title: "Are you sure?",
        subtitle: "Data will be lost",
        decline: "No",
        accept: "Yes",
    },
    moveOrCopyPriceList: {
        move: {
            title: "Transfer to category",
            yes: "Move",
            no: "Cancel",
        },
        copy: {
            title: "Copy to category",
            yes: "Copy",
            no: "Cancel",
        },
    },
    inputEmail: {
        title: "Connect your email account",
        inputLabel: "Email",
        confirm: "Confirm",
        cancel: "Cancel",
    },
    importExport: {
        export: "Export",
        import: "Import",
        downloadAll: "Download all",
        downloadFiltered: "Download only filtered",
        all: "All",
        startExport: "Export",
        startImport: "Import",
    },
    changesNotSavedModal: {
        title: "Are you sure you want to leave the page?",
        subtitle: "Your changes are not saved.",
        confirm: "Confirm",
        cancel: "Cancel",
    },
    makeCallErrorModal: {
        subtitleNoNumber: "You cannot perform a call without a dialing system number set. Contact your administrator",
        subtitleSipIsNotActive: "SIP connection lost. Please check your network and try reloading the page before calling.",
        confirm: "Confirm",
    },
};

export default modals;
