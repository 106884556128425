import api from "@/services/api";
import {
    ConfirmEmail,
    Login,
    ResendCode,
    ResetPasswordConfirmation,
    SignUp,
    SignUpByInvitation,
    SignUpInvitationCheck,
    TwoFaAuthenticate,
    UpdateProfile,
} from "@/services/types";
import { backendFrontendLocalsMap } from "../utils/constants";

export const authService = {
    async getProfile() {
        return api.get("/auth/profile");
    },
    async updateProfile(payload: UpdateProfile): Promise<void> {
        const formData = new FormData();

        Object.entries(payload).forEach(([key, value]) => formData.append(key, value as string));

        const response = await api.patch(`/users/profile`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 1000 * 60 * 20,
        });

        return response.data;
    },

    async getCentrifugoCredentials() {
        const res = await api.get("/auth/centrifuge");
        return res.data;
    },

    async signUp(payload: SignUp) {
        return api.post("/auth/sign-up", payload);
    },

    async checkSignUpInvitation(payload: SignUpInvitationCheck) {
        try {
            const response = await api.post("/auth/sign-up/invitation/check", payload);
            return response.data;
        } catch (e) {
            console.error(e);
            return false;
        }
    },
    async signUpByInvitation(payload: SignUpByInvitation) {
        return api.post("/auth/sign-up/invitation", payload);
    },

    async resendCode(payload: ResendCode) {
        return api.post("/auth/resend-code", payload);
    },

    async confirmEmail(payload: ConfirmEmail) {
        return api.post("/auth/email-confirmation", payload);
    },

    async login(login: Login) {
        return api.post("/auth/login", login);
    },

    async resetPassword(login: string) {
        return api.post("/auth/reset-password", { login });
    },

    async resetPasswordCheckCode(payload: ConfirmEmail) {
        return api.post("/auth/reset-password/check-code", payload);
    },

    async resetPasswordConfirmation(payload: ResetPasswordConfirmation) {
        return api.post("/auth/reset-password/confirmation", payload);
    },

    refreshToken() {
        return api.get("/auth/refresh-token");
    },

    logout() {
        return api.post("/auth/logout");
    },

    async signInGoogleUrl() {
        return api.getUri() + "/auth/google";
    },

    async generate2FA() {
        const res = await api.post("/auth/google-authenticator/generate");
        return res.data;
    },

    async updateGoogleAuthenticator(id: number, name: string) {
        const res = await api.patch(`/auth/google-authenticator/${id}`, { name });
        return res.data;
    },

    connectGoogleAuthenticator(payload: { verificationCode: string; verifierName: string }) {
        return api.post("/auth/google-authenticator/connect", payload);
    },

    async removeGoogleAuthenticator(id: number) {
        const res = await api.delete(`/auth/google-authenticator/${id}`);
        return res.data;
    },

    async getUserIssuers() {
        const res = await api.get("/auth/google-authenticator/issuers");
        return res.data;
    },

    async authenticate2FA(payload: TwoFaAuthenticate) {
        return api.post("/auth/google-authenticator/authenticate", payload);
    },

    async update2FASettings(data: { email2FAEnabled?: boolean; authenticator2FAEnabled?: boolean }) {
        return api.patch("/auth/2fa", data);
    },

    async setLocale(locale: "en-us" | "uk-ua") {
        return api.post("/auth/set-locale", { locale: backendFrontendLocalsMap[locale] });
    },
};
