import { ref } from "vue";
import axios from "axios";

export function usePublicIp() {
    const myPublicIp = ref("");
    const apiForGetMyPublicIP = ["https://ipapi.co/json", "https://api.ipify.org?format=json"];

    const getMyPublicIp = async () => {
        const getIp = async (url: string) => {
            const response = await axios.get(url);
            myPublicIp.value = response.data?.ip;
        };

        try {
            await getIp(apiForGetMyPublicIP[0]);
        } catch (e) {
            try {
                await getIp(apiForGetMyPublicIP[1]);
            } catch (e) {
                console.error("Failed to get public IP from all sources");
            }
        }

        if (!myPublicIp.value) throw new Error(`Failed to get my public IP`);
        return myPublicIp.value;
    };

    return {
        myPublicIp,
        getMyPublicIp,
    };
}
