import { ref, computed } from "vue";
import { callsService } from "@/services";
import { CallTypes } from "@/services/enums/call-types.enum";
import { CreateCall } from "@/services/types/calls/create-call";
import { useRootStore } from "@/utils/hooks";

export function useCallRecords() {
    let callStartTime: string = new Date().toISOString();
    const store = useRootStore();
    const destinationCallId = computed(() => store.state.calls.destinationCallId);
    const sourceCallId = computed(() => store.state.calls.sourceCallId);

    const startCallTracking = () => {
        callStartTime = new Date().toISOString();
    };

    function determineCallType(isCallerRole: boolean, hasActiveCallSession: boolean): CallTypes {
        if (isCallerRole) {
            return hasActiveCallSession ? CallTypes.OUTGOING : CallTypes.MISSED;
        } else {
            return hasActiveCallSession ? CallTypes.INCOMING : CallTypes.MISSED;
        }
    }

    async function saveCallRecord(
        isCallerRole: boolean,
        hasActiveCallSession: boolean,
        sipNumber: string | null,
        phoneNumber: string | null,
        remotePhoneNumber: string | null,
        leadId: number | null,
        clientId: number | null,
        dealId: number | null
    ) {
        const callType = determineCallType(isCallerRole, hasActiveCallSession);

        const sourceNumber = isCallerRole ? sipNumber : remotePhoneNumber;
        const targetNumber = isCallerRole ? phoneNumber : sipNumber;

        if (!sourceNumber || !targetNumber || sourceNumber === targetNumber) return;

        const call: CreateCall = {
            type: callType,
            sourceNumber,
            targetNumber,
            internalNumber: "",
            startedAt: callStartTime,
            finishedAt: new Date().toISOString(),
            dealId: dealId || undefined,
        };

        if (callType === CallTypes.INCOMING) {
            call.destinationCallId = destinationCallId.value ?? undefined;
            if (leadId) call.sourceLeadContactId = Number(leadId);
            if (clientId) call.sourceClientContactId = Number(clientId);
        } else if (callType === CallTypes.OUTGOING) {
            call.sourceCallId = sourceCallId.value ?? undefined;
            if (leadId) call.targetLeadContactId = Number(leadId);
            if (clientId) call.targetClientContactId = Number(clientId);
        }

        try {
            await callsService.createCall(call);
            store.commit("calls/setDestinationCallId", null);
            store.commit("calls/setSourceCallId", null);
            return true;
        } catch (error) {
            console.error("Error saving call record:", error);
            store.commit("calls/setDestinationCallId", null);
            store.commit("calls/setSourceCallId", null);
            return false;
        }
    }

    return {
        startCallTracking,
        saveCallRecord,
    };
}
