import { ref, onMounted } from "vue";

export function useCallAudio() {
    const toneAudioElement = ref(new Audio("/sounds/tone.mp3"));
    const ringtoneAudioElement = ref(new Audio("/sounds/ringtone.mp3"));
    const audioUnlocked = ref(false);

    onMounted(() => {
        toneAudioElement.value.loop = true;
        ringtoneAudioElement.value.loop = true;
        document.body.addEventListener("click", unlockAudio, { once: true });
    });

    const unlockAudio = async () => {
        try {
            toneAudioElement.value.muted = true;
            await toneAudioElement.value.play();
            toneAudioElement.value.pause();
            toneAudioElement.value.muted = false;

            ringtoneAudioElement.value.muted = true;
            await ringtoneAudioElement.value.play();
            ringtoneAudioElement.value.pause();
            ringtoneAudioElement.value.muted = false;

            audioUnlocked.value = true;
            console.log("Audio context unlocked");
        } catch (error) {
            console.error("Error unlocking audio:", error);
        }
    };

    const playRingtone = async () => {
        try {
            await ringtoneAudioElement.value.play();
        } catch (error) {
            console.warn("Ringtone playback failed:", error);
        }
    };

    const playTone = async () => {
        try {
            await toneAudioElement.value.play();
        } catch (error) {
            console.warn("Tone playback failed:", error);
        }
    };

    const stopAudio = () => {
        toneAudioElement.value.pause();
        ringtoneAudioElement.value.pause();
    };

    const playAudio = (source: string | MediaStream, isStream = false, isLoop = false) => {
        try {
            const audioElement = document.getElementById("audio") as HTMLAudioElement;
            if (!audioElement) {
                console.error("Audio element not found");
                return;
            }

            if (isStream && typeof source !== "string") {
                audioElement.srcObject = source;
                audioElement.oncanplay = () => {
                    audioElement.play().catch((error) => {
                        console.error("Error playing audio", error);
                    });
                };
            } else if (typeof source == "string") {
                audioElement.src = source;
                audioElement.oncanplay = () => {
                    audioElement.play().catch((error) => {
                        console.error("Error playing ringing audio", error);
                    });
                };
            }

            audioElement.loop = isLoop;
            audioElement.play();
        } catch (error) {
            console.error("Error playing audio", error);
        }
    };

    const headerIcons = {
        default: "favicon.png",
        withSound: "favicon-sound.png",
        online: "favicon-online.png",
    };

    const changeFavicon = (path: string) => {
        let link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
        if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.head.appendChild(link);
        }
        link.href = path;
    };

    const showIncomingCallIndicator = () => {
        changeFavicon(headerIcons.withSound);
    };

    const clearCallIndicator = () => {
        changeFavicon(headerIcons.default);
    };

    return {
        audioUnlocked,
        unlockAudio,
        playRingtone,
        playTone,
        stopAudio,
        playAudio,
        showIncomingCallIndicator,
        clearCallIndicator,
        headerIcons,
        changeFavicon,
    };
}
