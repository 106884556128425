import { createRouter, createWebHistory } from "vue-router";
import { routes } from "@/router/routes";
import store from "@/store";
import { isAccessToRoute, isAuthorized } from "./utils";

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (!store.getters["auth/userProfile"]) {
        await store.dispatch("auth/getProfile");
    }

    if (!isAuthorized(to)) {
        window.location.href = "/auth";
    }
    if (!isAccessToRoute(to)) {
        return next("/404");
    }
    if (!isAccessToRoute(to)) return next("/404");

    next();
});

export default router;
