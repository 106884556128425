const additionalContacts = {
    title: "Додаткові контакти",
    add: "Додати",
    emptyMessage: "Таблиця не містить даних",
    emptySearchResult: "Даних не знайдено за вашим запитом",
    columns: {
        fullName: "ПІБ",
        email: "Імейл",
        phoneNumber: "Телефону",
        phoneCountry: "Країна телефону",
        position: "Посада",
        city: "Місто",
        postDepartment: "Відділення пошти",
        taskCount: "Кількість завдань",
        dealCount: "Кількість угод",
        actions: "Дії",
        firstName: "Ім'я",
        lastName: "Прізвище",
        middleName: "По-батькові",
        id: "ID",
        novaPostDeliveryAddress: "Адреса нової пошти",
        novaPostDeliveryAddressCity: "Місто (нова пошта)",
        novaPostDeliveryAddressDepartment: "Відділення нової пошти",
        ukrPostDeliveryAddress: `Адреса укрпошти`,
    },
    modal: {
        headers: {
            create: "Створити додатковий контакт",
            update: "Оновити додатковий контакт",
        },
        inputs: {
            firstName: "Ім'я",
            lastName: "Прізвище",
            middleName: "По-батькові",
            position: "Посада",
            email: "Email",
            phoneNumber: "Телефон",
            phoneCountry: "Країна телефону",
            city: "Місто",
            postDepartment: "Відділення пошти",
        },
        create: "Створити",
        update: "Оновити",
        errors: {
            noChanges: "Для оновлення, будь ласка, змініть хоча б одне поле",
            required: "Будь ласка, перевірте всі обов'язкові поля",
            invalid: "Будь ласка, перевірте коректність введених даних",
        },
    },
};

export default additionalContacts;
