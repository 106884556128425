const multiActions = {
    edit: {
        header: "Mass actions",
        apply: "Apply",
        clear: "Clear",
    },
    panel: {
        checked: "Selected",
        elements: "element(-s)",
        selectAll: "Select all",
    },
    modals: {
        success: {
            title: "Changes applied",
        },
        successCopied: {
            title: "The link has been copied",
        },
    },
};

export default multiActions;
