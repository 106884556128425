import { enUsLocaleName, ukUaLocaleName } from "@/utils/constants";
import Joi from "joi";
import { emailSchema, noEmptyString, phoneNumberSchema, referenceSchema } from "@/utils/validation-schemas/common/";

const nameErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Ім'я ліда не може бути порожнім.",
        "string.max": "Ім'я не може містити більше 100 символів",
        "string.min": "Ім'я не може містити менше 3 символів",
    },
    [enUsLocaleName]: {
        "string.empty": "Lead name cannot be empty.",
        "string.max": "Lead name cannot exceed 100 characters.",
        "string.min": "Lead name cannot be less than 3 characters.",
    },
};

const responsibleIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "відповідальний" є обов\'язковим.',
        "number.positive": 'Поле "відповідальний" є обов\'язковим.',
        "number.integer": 'Поле "відповідальний" є обов\'язковим.',
        "any.required": 'Поле "відповідальний" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "responsible" field is required.',
        "number.positive": 'The "responsible" field is required.',
        "number.integer": 'The "responsible" field is required.',
        "any.required": 'The "responsible" field is required.',
    },
};

const typeIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "типу" є обов\'язковим.',
        "number.positive": 'Поле "типу" є обов\'язковим.',
        "number.integer": 'Поле "типу" є обов\'язковим.',
        "any.required": 'Поле "типу" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "type" field is required.',
        "number.positive": 'The "type" field is required.',
        "number.integer": 'The "type" field is required.',
        "any.required": 'The "type" field is required.',
    },
};

const commentErrMsgs = {
    [ukUaLocaleName]: {
        "string.max": "Коментар не може перевищувати 500 символів.",
        "string.empty": "Поле коментарю не може бути порожнім.",
    },
    [enUsLocaleName]: {
        "string.max": "Comment cannot exceed 500 characters.",
        "string.empty": "Comment field cannot be empty.",
    },
};

const sourceIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "ID джерела має бути числом.",
        "number.integer": "ID джерела має бути цілим числом.",
        "any.allowOnly": "ID джерела може бути -1 або додатним числом.",
    },
    [enUsLocaleName]: {
        "number.base": "Source ID must be a number.",
        "number.integer": "Source ID must be an integer.",
        "any.allowOnly": "Source ID can only be -1 or a positive number.",
    },
};

const statusIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "ID статусу має бути числом.",
        "number.integer": "ID статусу має бути цілим числом.",
        "number.positive": 'Поле "статус" є обов\'язковим.',
        "any.allowOnly": "ID статусу може бути -1 або додатним числом.",
        "any.required": 'Поле "статус" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": "Status ID must be a number.",
        "number.integer": "Status ID must be an integer.",
        "number.positive": "Status field is required.",
        "any.allowOnly": "Status ID can only be -1 or a positive number.",
        "any.required": "Status field is required.",
    },
};

const contactPersonErrMsgs = {
    [ukUaLocaleName]: {
        "string.min": 'Поле "контактна особа" має містити щонайменше 3 символи.',
        "string.max": 'Поле "контактна особа" не може містити більше 10 символів.',
    },
    [enUsLocaleName]: {
        "string.min": 'The "contact person" must be at least 3 characters.',
        "string.max": 'The "contact person" cannot exceed 10 characters.',
    },
};

const ukrPostDeliveryAddressErrMsgs = {
    [ukUaLocaleName]: {
        "object.base": "Адреса доставки Укрпошти має бути об'єктом або null.",
    },
    [enUsLocaleName]: {
        "object.base": "Ukr Post delivery address must be an object or null.",
    },
};

const postDepartmentErrMsgs = {
    [ukUaLocaleName]: {
        "object.base": "Поле 'Відділення пошти' має бути об'єктом.",
        "any.required": "Поле 'Відділення пошти' є обов'язковим.",
    },
    [enUsLocaleName]: {
        "object.base": "The 'Post Department' field must be an object.",
        "any.required": "The 'Post Department' field is required.",
    },
};

const postDepartmentTypeErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Поле 'Тип відділення' має бути рядком.",
        "any.required": "Поле 'Тип відділення' є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.base": "The 'Department Type' field must be a string.",
        "any.required": "The 'Department Type' field is required.",
    },
};

const cityErrMsgs = {
    [ukUaLocaleName]: {
        "object.base": "Поле 'Місто' має бути об'єктом.",
        "any.required": "Поле 'Місто' є обов'язковим.",
    },
    [enUsLocaleName]: {
        "object.base": "The 'City' field must be an object.",
        "any.required": "The 'City' field is required.",
    },
};

export const leadRequiredFields: string[] = ["name", "email", "phoneNumber", "responsible", "type", "status"];

const commonFields = Joi.object({
    name: noEmptyString.min(3).max(100).required().messages(nameErrMsgs),
    email: emailSchema.required(),
    contactPerson: noEmptyString.min(3).max(50).optional().allow("", null).messages(contactPersonErrMsgs),
    phoneNumber: phoneNumberSchema.required(),
    comment: noEmptyString.optional().max(500).allow("", null).messages(commentErrMsgs),
    createdAt: Joi.date().optional(),
    novaPostDeliveryAddress: Joi.object({
        city: Joi.object().required().messages(cityErrMsgs),
        postDepartmentType: Joi.string().required().messages(postDepartmentTypeErrMsgs),
        postDepartment: Joi.object().required().messages(postDepartmentErrMsgs),
    }).allow(null),
    ukrPostDeliveryAddress: Joi.object().allow(null).messages(ukrPostDeliveryAddressErrMsgs),
}).or("email", "phoneNumber");

export const createLeadSchema = commonFields.append({
    responsibleId: Joi.number().integer().positive().required().messages(responsibleIdErrMsgs),
    typeId: Joi.number().integer().positive().required().messages(typeIdErrMsgs),
    sourceId: Joi.number().integer().allow(null, -1).messages(sourceIdErrMsgs),
    statusId: Joi.number().integer().positive().required().messages(statusIdErrMsgs),
});

export const updateLeadSchema = commonFields.append({
    responsible: Joi.object(referenceSchema).messages(responsibleIdErrMsgs),
    type: Joi.object(referenceSchema).messages(typeIdErrMsgs),
    source: Joi.object(referenceSchema).messages(sourceIdErrMsgs),
    status: Joi.object(referenceSchema).messages(statusIdErrMsgs),
});
