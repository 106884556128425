const errors = {
    internalServerError: "Internal server error",
    retrievingDataError: "Error retrieving data",
    smthWentWrong: "Something went wrong. Please try again.",
    notFoundTitle: "Oops, page not found!",
    notFoundSubtext: "It seems that such page does not exist or has been moved.",
    backToMain: "Back to main page",
};

export default errors;
