import Cookies from "js-cookie";
import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { toast } from "@/utils";
import i18n from "@/i18n";
import { errMsgRetriever } from "@/utils/errors";
import { ErrorCodes } from "@/utils/constants";
import { authService } from "../auth.service";

export const interceptResponseErrors = async (error: AxiosError) => {
    if (error.response?.status === 401) {
        const refreshToken = Cookies.get("check");

        if (refreshToken) {
            try {
                await axios.get(`${process.env.VUE_APP_API_BASE_URL}/auth/refresh-token`, { withCredentials: true });

                if (error.config) {
                    return axios.request(error.config as InternalAxiosRequestConfig);
                } else {
                    toast.error("Issue with refresh tokens");
                    await authService.logout();
                    return;
                }
            } catch (refreshError) {
                toast.error("Issue with refresh tokens");
                await authService.logout();
                return;
            }
        }
    } else if (error.response?.status === 500) {
        //@ts-ignore
        const errCode = error.response?.data?.error;

        if (errCode === ErrorCodes.ExternalServiceError || errCode === ErrorCodes.HandledInternalServerErr) {
            const msg = errMsgRetriever(error, false);
            toast.error(msg);
        } else if (error.config?.method === "get") {
            toast.error(`${i18n.global.t("errors.retrievingDataError")}. ${i18n.global.t("errors.internalServerError")}`);
        } else {
            toast.error(i18n.global.t("errors.internalServerError"));
        }
    } else if (error.config?.method === "get") {
        // we don't have any place in UI to show GET requests errors
        toast.error(i18n.global.t("errors.retrievingDataError"));
    }

    // todo add condition for env
    console.error(error);

    return Promise.reject(error);
};
