import { ref } from "vue";
import { referencesService } from "@/services";
import { Deal, Task } from "@/services/types";
import { useToast } from "vue-toastification";
import { useRootStore } from "@/utils/hooks";

export function useCallRelatedData() {
    const toast = useToast();
    const store = useRootStore();

    const tasks = ref<Partial<Task>[]>([]);
    const deals = ref<Partial<Deal>[]>([]);

    const getTasksAndDealsByPhoneNumber = async (phoneNumber: string | null) => {
        if (!phoneNumber) {
            console.log("No phone number provided to fetch related data");
            return;
        }

        try {
            const response = await referencesService.getTasksAndDealsByPhoneNumber(phoneNumber);
            tasks.value = response.tasks;
            deals.value = response.deals;
            return { tasks: response.tasks, deals: response.deals };
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || String(error);
            toast.error(errorMessage);
            console.error("Error fetching tasks and deals:", error);
            return { tasks: [], deals: [] };
        }
    };

    const getLeadOrClientIdByPhoneNumber = async (phoneNumber: string | null, currentDealId: number | null) => {
        const result = { leadId: null, clientId: null };

        if (!phoneNumber) {
            console.log("No phone number provided to get lead/client ID");
            return;
        }

        try {
            const contactData = await referencesService.getLeadOrClientByPhone(phoneNumber);
            result.leadId = contactData?.leadId || null;
            result.clientId = result.leadId ? null : contactData?.clientId || null;

            if (result.leadId || result.clientId) {
                store.commit("modals/setParams", {
                    dealId: currentDealId || null,
                    ...result,
                });
            }
            return result;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || String(error);
            console.error("Failed to fetch lead/client info:", error);
            toast.error(errorMessage);
            return result;
        }
    };

    return {
        tasks,
        deals,
        getTasksAndDealsByPhoneNumber,
        getLeadOrClientIdByPhoneNumber,
    };
}
