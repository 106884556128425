const dashboard = {
    title: "Dashboard",
    subtitle: "Track the essentials on the Dashboard",
    totalRange: "Total Range",

    leadCount: {
        title: "Sales activity",
        subtitle: "Track the essentials on the Dashboard",
        counts: {
            lead: "Leads",
            client: "Clients",
            deal: "Deals",
        },
    },

    callCount: {
        title: "Calls count",
        subtitle: "Track the essentials on the Dashboard",
        counts: {
            incoming: "Inbound calls",
            outgoing: "Outbound calls",
            missed: "Missed calls",
            all: "All",
        },
    },

    leadPercentage: {
        title: "New data structure",
        subtitle: "Track the essentials on the Dashboard",
        noData: "No data for this period",
        counts: {
            label: "Count",
            leads: "Leads",
            clients: "Clients",
            deals: "Deals",
        },
    },
};

export default dashboard;
